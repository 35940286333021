.mobile-input {
  outline: none;
  border-radius: 100px;
  border: none;
  width: 100%;
  padding: 8px 12px;
  background-color: #2121210a;
}

.mobile-input__eye {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

.mobile-link {
  font-weight: bold;
  color: #04B6F5;
}

.mobile-link:hover {
  color: #04a3dc;
}

.mobile-btn {
  outline: none;
  border: none;
  background-color: #04B6F5;
  padding: 10px 15px;
  color: white;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 500;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: -webkit-box-shadow .15s;
  transition: -webkit-box-shadow .15s;
  transition: box-shadow .15s;
  transition: box-shadow .15s, -webkit-box-shadow .15s;
}

.mobile-btn:hover {
  background-color: #04a3dc;
}

.mobile-btn:focus {
  -webkit-box-shadow: 0 0 1px 3px #63d4fc;
          box-shadow: 0 0 1px 3px #63d4fc;
}

.mobile-btn:disabled {
  background-color: #95e2fd;
  cursor: default;
}

.oauth-img:hover {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}
