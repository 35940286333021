.hr {
    height: 4px !important;
    background-color: #00baff;
    width: 45%;
    opacity: 1;
    margin-left: 30px;
}

.container {
    display: flex;
    gap: 16px;
    justify-content: stretch;
}

.container > * {
    flex: 1 1;
}

.vidCard, .audioCard {
    min-height: 250px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0 7px 0 #00000083;
    transform: none;
    transition: .2s box-shadow, .2s transform;
}

.vidCard:hover, .audioCard:hover {
    transform: scale(1.01);
    box-shadow: 0 3px 12px 0 #0000009f;
}

.vidCard span, .audioCard span {
    position: absolute;
    right: 0;
    bottom: 40px;
    padding: 8px 12px;
    font-size: 18px;
    color: white;
    background-color: #00baff;
}

.vidCard {
    background-image: url('/assets/images/callback_video.jpg');
}

.audioCard {
    background-image: url('/assets/images/callback_audio.jpg');
}

@media (max-width: 996px) {
    .container {
        flex-direction: column-reverse;
    }
}

.rs {
    width: 100%;
    aspect-ratio: 2/1;
}

.rs, .ls {
    border-radius: 4px;
}

.rs {
    background-color: gray;
}

.ls {
    background-color: darkgray;
    position: absolute;
    bottom: 5%;
    left: 2.5%;
    aspect-ratio: 2/1;
    height: 110px;
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
}

.controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 40px;
}

.decline {
    transition: .15s all;
}

.decline:hover {
    transform: scale(1.1);
    cursor: pointer;
}