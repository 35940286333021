.toastNotificationPosition{
    position: fixed;
    top: 85px;
    left: calc(100% - 400px);
    z-index: 999;
    display: block;
    background: #04b6f6;
    color: #fff;
    font-size: 14px;
}

.toast-header{
    display: block;
    height: 26px;
    padding: 3px;
}

.toast-header .btn-close{
    width: 20px;
    height: 20px;
    font-size: 10px;
    padding: 0px;
    margin: 0;
    line-height: 20px;
}