

.testimonials_item {
    display: grid;
    grid-template-rows: 1fr min-content min-content min-content;
    grid-template-columns: auto auto;
    row-gap: 10px;
    column-gap: 20px;
    color: #fff;
    font-size: 25px;
    line-height: 30px;
    float: left;
    border: 4px solid white;
    padding: 50px;
    position: relative;
    margin-top: 200px;
    margin-bottom: 50px;
}

.testimonials_image_wrapper {
    grid-column: 2;
    grid-row: 1 / span 4;

    width: 334px;
}

.testimonials_image {
    bottom: 0;
    right: 0;
    position: absolute;
}

.testimonials_text {
    grid-column: 1;
    grid-row: 2;
}

.testimonials_auth, .testimonials_whois {
    text-align: right;
}

.testimonials_auth {
    grid-column: 1;
    grid-row: 3;
}

.testimonials_whois {
    grid-column: 1;
    grid-row: 4;

    font-size: 1.2rem;
}

.testimonials_separator {
    height: 4px;
    width: 100px;
    color: white;
    opacity: 1;
    border-bottom: 4px dashed white;
    margin: 0 auto 50px;
    display: none;
}

@media screen and (max-width: 992px) {
    .testimonials_item {
        grid-template-rows: repeat(4, auto);
        grid-template-columns: auto;
        margin-top: auto;
        padding: 50px 0;

        border: none;
    }
    
    .testimonials_image_wrapper {
        grid-column: 1;
        grid-row: 2;
        width: 200px;
        margin: 0 auto;
        text-align: center;
        border: 4px solid white;
        border-radius: 50%;
        overflow: hidden;

    }
    
    .testimonials_image {
        position: relative;
        height: 200px;
        width: auto;
    }
    
    .testimonials_text {
        grid-row: 1;
        text-align: center;
        margin-bottom: 50px;
    }

    .testimonials_auth, .testimonials_whois {
        text-align: center;
    }
    
    .testimonials_auth {
        grid-row: 3;
    }
    
    .testimonials_whois {
        grid-row: 4;
    }

    .testimonials_separator {
        display: block;
    }
} 