.reestr_itemlistbtn {
    position: relative;
    display: block;
    padding-left: 0px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.is .btn {
    background: #fff;
    color: #000;
    padding: 5px 10px;
    border-radius: 3px;
    margin-top: 0px;
    position: relative;
	border: solid 1px #ccc;
    display: block;
	text-decoration:none;
	cursor:pointer;
}
.is .btn:hover::after {
    content:" ";
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background:rgba(255,255,255,.3);
}

.is .btn.active::after {
    content:" ";
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background:rgba(0,0,0,.1);
}

.is .btn.btn-biryza {
    background: #01baff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
	border: solid 1px #01baff;
    margin-top: 0px;
    position: relative;
    display: block;
}

.is_search_advocat {
    height: 28px!important;
    border: solid 1px #ccc;
    border-radius: 3px;
    font-size:14px!important;
}

.is_search_advocat::placeholder {
    font-size:14px!important;
}

ol.breadcrumb {
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 15px;
    padding: 0;
}

li.breadcrumb-item {
    float: left;
    margin-right: 10px;
}
/*
li.breadcrumb-item:not(:last-child)::after {
	content:"/";
    margin-left: 10px;
}

.is_search_advocat{
	height:;
}/**/

.uk-card-default {
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px 5px rgba(0,0,0,0.12);
}
.uk-card-body {
    padding: 15px 15px;
}
.uk-accordion-title {
    border: solid 1px #ddd;
    border-radius: 6px;
    box-shadow: 0 10px 12px -15px #000;
}

.list-group {/*max-height: 34.375rem;*/
    height: 54vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.list-group-item {
    padding: 0.4rem 1rem;
}

html {
  --scrollbarBG: #fafafc; /*#f0f0f0;*/
  --thumbBG: #dfdfdf; /*#3f3f3f;*/ /*#00baff;*/
    --searchInputFontColor: #dfdfdf;
    --searchInputPhFontColor: #ddd;
}
.list-group::-webkit-scrollbar {
  width: 8px;
}
.list-group {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.list-group::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.list-group::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: .25rem;
  border: 1px solid var(--scrollbarBG);
}


/* search element */
.short-search-wrapper {
    position: relative;
    padding: 0;
    margin: 0;
    background: #fff;
    border-radius: 0;
    border: 1px solid #dfdfdf;
    width: 100%;
    z-index: 100;
    margin-bottom:20px;
}
.short-search-input {
    position: relative;
    width: calc(100% - 40px);
    line-height: 38px;
    font-size: 1rem;
    outline: none;
    padding-left: 15px;
    border: 0;
    padding-bottom: 2px;
    border-radius: 0;
    color: #212529; /*var(--searchInputFontColor);*/
    /*color: var(--bs-body-color);*/
}
.short-search-input::placeholder {
    color: var(--searchInputPlFontColor);
}
.short-search-input::-webkit-input-placeholder {
    color: var(--searchInputPlFontColor);
}
.short-search-input::-moz-placeholder { /* Firefox 19+ */
    color: var(--searchInputPlFontColor);
}
.short-search-wrapper .search_btn {
    width: 40px;
    background: transparent;
    border: 0;
    color: #ddd;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    cursor: default;
}
.short-search-wrapper .uk-search {
    position: relative;
}


a .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

a:hover .tooltiptext {
    visibility: visible;
}



.StateListWrapper .city-item::before {
    content: url('/assets/images/icons/location.svg');
    display: inline-block;
    width: 20px;
}
.StateListWrapper .city-item {
    padding: 12px 20px;
    margin: 20px 0;
    border: 1px solid rgba(0,0,0,.125);
    border-top-width: 2px;
    border-radius: 2px;
    box-shadow: 0 1px 5px 0 rgba(50,50,50,.05);
}