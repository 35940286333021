html, body{
	font-family: "Nova"; /* 1 */
	line-height: 1.15; /* 2 */
	-ms-text-size-adjust: 100%; /* 3 */
	-webkit-text-size-adjust: 100%; /* 3 */
}
.color_set_1{
	color: #00BAFF;
}
.header_icon i{
	font-size:20px;
}
.header_icon {
	font-size:18px;
}
.header_our_subline {
    color: #888;
    margin-top: 8px;
    font-size: 13px;
}

.toppanel-wrapper .uk-navbar-nav > li > a{
    color: #fff;
	text-transform:uppercase;
}
.toppanel-wrapper {
    background: #212A34;
    height: 65px;
	position: relative;
}
.toppanel-wrapper nav > ul > li > a{
    height: 65px;
	line-height: 65px;
}
.toppanel-wrapper nav > div{
    background: #00BAFF;
    height: 65px;
	line-height: 65px;
	color:#fff;
    font-size: 20px;
}
.toppanel-wrapper nav input{
    padding-left: 40px;
	color:#fff;
}
.toppanel-wrapper nav input::placeholder{
	color:#fff;
	font-weight:500;
}
.toppanel-wrapper nav > div .uk-search:before{
    font-size: 20px;
    line-height: 65px;
	color:#fff;
	margin-right:10px;
}
.toppanel-wrapper nav > ul > li.uk-active > a{
    background: rgba(255,255,255,0.2);
}
.toppanel-wrapper nav > ul > li.uk-active > a:hover{
    background: rgba(255,255,255,0.5);
}
.toppanel-wrapper nav > ul > li > a:hover{
    background: rgba(255,255,255,0.2);
}

.uk-navbar-toggle{
    color: #fff!important;
    text-decoration: none;
    outline: none;
    margin-top: 10px!important;
    font-size: 36px!important;
}


.innerpage .toppanel-wrapper:after {
	content: " ";
    position: absolute;
    height: 140px;
    left: -6%;
    border-radius: 50%;
    bottom: -100px;
    background: #212A34;
    width: 112%;
    z-index: -1;
}

.toppanel-wrapper-overview {
    height: 270px;
    overflow: hidden;
}

.uk-accordion-chapter span, .uk-accordion-article span, .uk-accordion-subsection span {
    color: #000;
    font-size: 14px;
}

.uk-accordion-article-content {
    color:#000;
    font-size:14px;
}

.uk-accordion-subsection span, .uk-accordion-section span, .uk-accordion-chapter span, .uk-accordion-article span{
    cursor:pointer;
}

.uk-accordion-article_item {
    float: left;
    font-size: 14px;
    margin-right: 12px;
}
.quick-start-item-bottomtitle span {
    color: #fff;
}


/*
	PARALLAX
*/

#main_content {
	width:100%;
    height: 100px;
    position: relative;
    margin-top: -100px;
}

@media (max-width: 800px) {
	#main_content {
		height: 665px;
	}
	.text-container {
		background: rgba(255,255,255,.7);
		padding: 10px 20px;
		margin-right: 20px;
	}
}
 
.uk-accordion-section span {
    color: #000;
    text-decoration: underline;
    font-size: 14px;
}


#scene{
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}


.img-1{
	margin-left: -55px;
    margin-top: -15px;
}

.img-2{
    margin-top: -8%;
    margin-left: 5%;
}

.img-3{
	margin-top: -2%;
    margin-left: 3%;
}

.img-4{
    margin-top: -1%;
    margin-left: 2%;
}

.ground{
    height: 300px;
    width: 200%;
    background: white;
    margin-top: 19%;
    margin-left: -100%
}

.img-5{
    margin-top: -17%;
    margin-left: 10%;
}

.parallax_wrapper{
	width: 100%;
    height: 100px;
    position: relative;
}


.text-container {
    position: absolute;
    top: 20%;
}
.text-container .scene-text-title{
	font-size:61px;
	font-weight:500;
}
.text-container .scene-text-content{
	font-size:20px;	
	font-weight:500;
}

@media (max-width: 800px) {
	#main_content .scene-text-title {
		font-size: 42px;
	}
	.parallax_wrapper{
		height:950px;
	}
}

@media (max-width: 500px) {
	#main_content .scene-text-title {
		font-size: 32px;
		padding-bottom:20px;
	}
}



.wi_img-fix {
    position: relative!important;
    margin-bottom: 27px;
}
.uk_bk_element{
	float:left;
    color:#ccc;
    text-decoration: none;
}
.uk_bk_element:hover{
    color:#eee;
    text-decoration: none;
}
.uk_bk_element:not(:first-child)::before {
	content: ">";
	margin:0 10px;
	color:#fff;
	font-weight:600;
}
.uk_bk_element:last-child {
  color:#00baff;
}



.scene-button-container {
    position: absolute;
    top: 150%;
}

@media (max-width: 800px) {
	.scene-button-container {
		top: 105%;
	}
}

.elementor-widget-wrap-0 {
    position: absolute;
    left: -2%;
    top: 10%;
}

.elementor-widget-wrap-0 img {
    width: 40px;
    max-width: 40px;
}

.elementor-widget-wrap-1 {
    position: absolute;
    right: -5%;
    top: 100%;
}
.elementor-widget-wrap-1 img {
    transform: scale(0.3);
}

.elementor-widget-wrap-2 {
    position: absolute;
    right: -15%;
    top: -30%;
}
.elementor-widget-wrap-2 img {
    width: 70px;
    max-width: 70px;
}


.elementor-widget-wrap-3 {
    position: absolute;
    right: -10%;
    top: 10%;
}
.elementor-widget-wrap-3 img {
    transform: scale(0.3);
}

.elementor-widget-wrap-4 {
    position: absolute;
    left: -10%;
    top: 50%;
}


.elementor-widget-wrap-4 svg {
    transform: scale(3);
}


.features-hr, .all-hr {
    /*border-bottom: solid 2px #fff;/**/
    width: 25%;
	padding-bottom:10px;
	margin-bottom:10px;
}

/*
	FEATURES
*/

.features-wrapper {
    position: absolute;
    bottom: 30px;
    width: 94%;
    /*box-shadow: 0 0 25px 5px rgba(0,0,0,0.2);/**/
}

@media (max-width: 800px) {
	.features-wrapper{
		bottom: auto;
		top: 410px;
	}
}

.features-wrapper .features-item {
    float: left;
    width: 25%;
	text-decoration:none;
    position: relative;
    display: block;
}

@media (max-width: 800px) {
	.features-wrapper .features-item{
		width: 95%;
	}
}

.features-wrapper .features-hr {
    border-bottom: solid 2px #00BAFF;
    width: 50px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.features-wrapper .features-dark{
	background:#323A43;
	color:#fff;
}

.features-wrapper .features-blue{
	background:#005B7D;
	color:#fff;
}

.features-wrapper .features-lightblue{
	background:#00BAFF;
	color:#fff;
}

.features-wrapper .features-blue .features-hr, .features-wrapper .features-lightblue .features-hr{
    border-color: #fff;
}

.features-wrapper .bg_hover{
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
     -webkit-transition: background 0.5s;
     -moz-transition: background 0.5s;
     -o-transition: background 0.5s;
     transition: background 0.5s;
	background: rgba(255,255,255,0.0);
}

.features-wrapper a.features-item:hover > .bg_hover_to_dark{
     -webkit-transition: background 0.5s;
     -moz-transition: background 0.5s;
     -o-transition: background 0.5s;
     transition: background 0.5s;
	background: rgba(0,0,0,0.1);
}

.features-wrapper a.features-item:hover > .bg_hover_to_light{
     -webkit-transition: background 0.5s;
     -moz-transition: background 0.5s;
     -o-transition: background 0.5s;
     transition: background 0.5s;
	background: rgba(255,255,255,0.4);
}

.features-margin {
    margin: 5% 0 0 7%;
}

.uk-margin-large-top {
    margin-top: 20px !important;
}

.filter-search-title {
    font-size: 26px;
    color: #FFF;
    margin-bottom: 25px;
    position: relative;
}
.filter-search-title {
	
}

.filter-search-title:after {
    content: " ";
    width: 43%;
    border-bottom: solid 3px #00b8fd;
    position: absolute;
    bottom: -10px;
    left: 30px;
}
.filter-search-form input {
    background: #00baff;
    color: #fff;
    padding: 11px 5px 8px 40px;
    border: 0;
    margin-left: 0!important;
}
.filter-search-form input::placeholder {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
}

.filter-breadcrumbs-list {
    font-size: 14px; 
    color: #ccc;
} 

.filter-breadcrumb-item {
    color: #00BAFF;
    border-bottom: solid 2px #00BAFF;
}
.uk-accordion-statya{
    margin-left: 40px; 
	font-size:17px;
	color:#008cbf;
	margin-bottom:5px;
}
.uk-accordion-glava {
    font-size: 18px;
    color: #000;
    text-decoration: underline;
	margin-bottom:9px;
}

.separator-block {
    height: 75px;
    background: #253342;
    margin-top: 20px;
}
/*

	QUICK START

/**/

.all-hr-2 {
    border-width: 4px;
    width: 40%;
}

.quick-start-header-title {
    font-size: calc(1.325rem + .55vw);
    line-height: 2.2rem;
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 0;
    text-transform:uppercase;
}

@media (min-width: 1200px) {
.h2, h2 {
  font-size: 2rem;
}
.h1, h1 {
  font-size: 2.1rem;
}
}

.allhr.all-hr-2 {
    padding-bottom: 5px;
}

.quick-start-item-wrapper {
    position: relative;
    height: 350px;
    margin-bottom: 50px;
}

.quick-start-item-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 10px 0;
}

.quick-start-item-bottomtitle {
    position: absolute;
    bottom: 0;
    left: 11px;
    right: 11px;
    background: #00BAFF;
    color: #fff;
    padding: 6px;
    font-size: 12px;
    text-transform: uppercase;
}

.quick-start-item-subtitle {
    position: absolute;
    bottom: -25px;
	font-size:18px;
    left: 11px;
    right: 11px;
    padding: 3px;
}

.quick-start-list-wrapper>div{
    margin: 0 -10px;
}

.quick-start-title-wrapper{
	position:relative;
    margin-bottom: 0;
}
.page-panel.page .quick-start-title-wrapper{
    margin-bottom: 30px;
}
.news-page .quick-start-title-wrapper {margin-bottom: 10px !important;}
/*
.quick-start-header-title{
	font-size: 38px;
    line-height: 42px;
    font-weight: 500;
}*/
/*
.quick-start-title-wrapper:after {
    content: " ";
    border-bottom: solid 4px #00baff;
    width: 45%;
    position: absolute;
    bottom: 0;
}
*/

.quick-start-title-wrapper h1.quick-start-header-title {
    border-bottom: 2px solid #f0f0f0;
    margin: 8px 0 20px;
    padding: 0;
}
.quick-start-title-wrapper h1.quick-start-header-title  .quick-start-title-border {
    padding: 0 0 10px;
    margin-bottom: -1px;
    border-bottom-width: 3px;
    display: inline-block;
    border-bottom: 3px solid #00baff;
}

/*
	FILTER-SEARCH
*/
.filter-search-wrapper-overview { 
    height: 225px;
    overflow: hidden;
    position: relative;
    margin-bottom: 110px;
}

.filter-search {
    position: relative;
    background: #212A34;
    height: 155px;
    margin-top: 50px;
}


.filter-search:after, .filter-search:before {
    content: " ";
    position: absolute;
    height: 50px;
    left: -8%;
    border-radius: 50%;
    bottom: -15px;
    background: #212A34;
    width: 114%;
}

.filter-search:before {
    bottom: 135px;
}

.filter-search-buttons{
	color:#fff;
}

.filter-title {
    font-size: 18px;
    margin: 3px;
	
}

.filter-list, .filter-title {
    position: relative;
    float:left;
}

.filter-list li {
    position: relative;
    display: inline-block;
    padding: 5px 15px;
    font-size: 16px;
    color: #ccc;
}

.filter-list li.active {
    background: #00baff;
    color: #fff;
}

.filter-list li.active::after {
    content: " ";
    position: absolute;
    bottom: -10px;
    border-bottom: solid 3px #00baff;
    right: 10px;
    left: 10px;
}

.filter-search-form input {
    background: #00baff;
    color: #fff;
    padding: 8px 5px 8px 65px;
    border: 0;
}
.filter-search-form input:before {
    content: "!!!";
    position: absolute;
}

.filter-search-result-title-wrapper {
    background: #fff;
    margin-top: -100px;
    position: relative;
    border-radius: 30px;
    padding: 30px 50px;
    box-shadow: 0 0 30px -10px #006098;
	background: #FFF url(/assets/images/feedback/feather.png) no-repeat;
    background-size: revert;
    background-position-x: right;
}

.filter-search-icon-circle {
    height: 80px;
    width: 80px;
    background: #42474C;
    border-radius: 50px;
    margin-right: 30px ;
    position: relative;
    float: left;
}

.filter-search-icon-circle {
    margin: auto;
}

.filter-search-result-header-title {
    font-size: 28px;
}

.filter-search-result-header-subtitle {
    color: #00baff;
    font-weight: 500;
    font-size: 15px;
}
.filter-search-result-subtitle, .uk-accordion-content {
    padding: 24px 0;
    font-size: 24px;
    font-weight: 400;
}

.filter-search-result-accordion .uk-accordion-title {
    /*border-radius: 0 10px 10px 0;
    box-shadow: 0 0 42px -15px #006098;/**/
    position: relative;
    margin: 10px 0 0;
    background: #fff;
    z-index: 9;
    padding: 10px 90px 11px 15px;
    font-size: 18px;
    color: #000;
    border-color: #00baff;
    
}
.filter-search-result-accordion .uk-accordion-title:after {
    content: " ";
    position: absolute;
    right: 20px;
    height: 10px;
    width: 16px;
    background: url(/assets/images/arrow_down.png);
    background-size: cover;
    top: 38%;
}
.filter-search-result-accordion .uk-accordion-title[aria-expanded=true]:after {
    content: " ";
    position: absolute;
    right: 20px;
    height: 10px;
    width: 16px;
    background: url(/assets/images/arrow_down_white.png);
    background-size: cover;
    top: 38%;
}


.filter-search-result-accordion .uk-accordion-title.uk-active:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/*.filter-search-result-accordion-wrapper {
    padding: 0;
}
.filter-search-result {
    margin-top: -100px;
}/**/
.uk-accordion-content {
    padding: 15px 15px 0 15px;
    background: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    background: #fefefe;
}
.uk-accordion-content:empty {
    padding: 0;
    background: #fff;
    font-size: 16px;
    margin-bottom: 15px;
    background: #fefefe;
}
.filter-search-wrapper-overview-separator {
    margin-top: -100px;
    z-index: -1;
}
/*
	КАРТА ПЕРЕВЁРТЫШ
*/

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 150px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  z-index: -1;
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
  z-index:1;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; 
  backface-visibility: hidden;
  box-shadow: 0 0 25px 5px rgba(0,0,0,0.2);
    background: #fff;
}


.flip-card-front {
  z-index: -1;
}

.flip-card:hover .flip-card-front {
  z-index: 1;
}

/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateX(180deg);
  z-index: 1;
}



@media (max-width: 800px) {
	.features-margin {
		margin: 5% 0 0 10%;
	}
	.features-wrapper{
		bottom: auto;
		top: 410px;
	}
	.flip-card {
		height: 130px;
	}
}



/*
CONTACTS
 /**/
 

.contacts-item {
    margin: 40px 0;
    color: #aaa;
}

.contacts-wrapper .contacts-item i {
    color: #00baff;
    font-size: 25px;
}


/*
FOOTER
 /**/
 
 .footer-wrapper{
	 padding:20px 0;
	 background: rgb(43,63,84);
	 background: linear-gradient(90deg, rgba(43,63,84,1) 0%, rgba(33,42,52,1) 100%);
	 color:#fff;
 }
 
 .footer-logo-description{
	color:#ccc;
    max-width: 350px;
    font-size: 12px;
}

.footer-wrapper .footer-logo-description{
	margin-top:5px;
}

.footer-wrapper .uk-input {
    max-width: 100%;
    width: 100%;
    border: 0 none;
    padding: 0 10px;
    background: #fff;
    color: #777;
    transition: .2s ease-in-out;
    transition-property: color,background-color,border;
    height: 52px;
    vertical-align: middle;
    display: inline-block;
}

.footer-wrapper .uk-input {
    padding-right: 70px !important;
}

.uk-icon {
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0;
}
.uk-form-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 70px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #999;
}
.uk-form-icon-flip {
    right: -80px;
    left: auto;
}
@media (max-width: 800px) {
	.footer-wrapper .uk-input{
		width: 78%;
	}
	.uk-form-icon{
		width: 70px;
	}
	.uk-form-icon-flip{
		right: -26px;
	}
}

[class*='uk-inline'] {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}

svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
}

.uk-icon>* {
    transform: translate(0, 0);
}

svg:not(:root) {
    overflow: hidden;
}

a, .uk-link {
    /*color: #1e87f0;/**/
    text-decoration: none;
    cursor: pointer;
}
.list-group-item.active a{
    color: #fff!important;
}


.item-plenum {
    padding: 4px 15px;
    font-size: 14px;
}

.uk-switcher {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-subscribe-btn{
	text-decoration:none;
	color:#fff;
	background:#00baff;
    font-size: 26px;
}
.footer-subscribe-btn:hover{
	color:#ccc;
}

.footer-subscribe-wrapper {
    padding-top: 30px;
}

.footer-menu-list li {
    color: #ccc;
    font-size: 18px;
    margin-bottom: 17px;
}

.uk-nav-offcanvas > li > a{
	font-size:22px;
}

.uk-offcanvas-bar{
	background:#005B7D;
}

.uk-nav.uk-nav-offcanvas > li.uk-active > a {
    background: #00BAFF!important;
}

.quick-start-list-container {
    height: 425px;
    overflow: hidden;
} 

#situations p, /*.uk-accordion-article-content,*/
.wysiwig_content p
 {
    margin-top: 0;
    margin-bottom: 1rem;
    /*text-indent: 1.5em;*/
}

.wysiwig_content p, ul, li {
    font-size: 1rem;
    line-height: 24px;
    color: #000;
	margin-top: 0;
    margin-bottom: 1rem;
}
.wysiwig_content ul {
    list-style: none inside;
}
.wysiwig_content h4 {
    margin-bottom: 1.5rem;
}


.wysiwig_content .notice-container {
    border: 1px solid #f0f0f0;
    border-left: 5px solid #00baff;
    padding: 15px 20px;
    margin-bottom: 20px;
    background-color: #fff;
}

.filter-search-form {
    position: relative;	
}
/*
.btn.btn-primary {
    content: "\f002";
    background: #00baff;
    top: 0;
    color: #fff;
    padding: 7px 5px 8px 5px;
    margin-right: -15px;
    z-index: 999;
    border-right: solid 1px #fff;
}
/**/
.quick-start-list-wrapper {
    padding-bottom: 10px;
}
.situation-item-wrapper {
    margin: 0 -30px 15px;
    margin-left: 0;
}

/*.situation-item-wrapper a {
    padding: 5px 5px;
    border: solid 1px #000;
    border-radius: 4px;
}*/
