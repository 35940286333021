.callback-bt {
    background: #fff;
    border: 2px solid #333333;
    /*border-radius: 50%;/**/
    box-shadow: 0 8px 10px #333333ff;
    cursor: pointer;
    height: 68px;
    text-align: center;
    width: 68px;
    right: 8%;
    bottom: 18%;
    z-index: 999;
    transition: .3s;
    animation: hoverWave 1s linear infinite;
    display: inline-block;
    line-height: 78px;
}

.callback-bt:first-child{
    border-radius:10px 0 0 10px;
}

.callback-bt:last-child{
    border-radius:0 10px 10px 0;
}

.hang-up{
    background: white !important;
    color: red !important;
}
.callback-bt i {
    color: #333;
    font-size: 34px;
    transition: .3s;
    line-height: 66px;
    transition: .5s ease-in-out;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.callback-bt-left {
    border-radius: 15px 0 0 15px;
}
.callback-bt-right {
    border-radius: 0 15px 15px 0;
    border-left:0;
}
div#clientVideoWrapperInner {
    background: #333;
    background: radial-gradient(rgb(191, 229, 247), rgb(43, 104, 133));
}

@media (min-width: 300px) {
    /*.containerClientVideoWrapper{
        height : 260px;
    }/**/
    .clientVideoWrapper{
        position: relative!important;
    }

    #clientVideo {
        max-height: 204px;
    }
    #clientVideoWrapperInner {
        max-height: 257px;
        position: relative!important;
    }

}

/* For Tablets */
@media (min-width: 700px) {
    /*.containerClientVideoWrapper{
        height : 260px;
    }/**/
    .clientVideoWrapper{
        position: relative!important;
    }

    #clientVideo {
        max-height: 300px;
    }
    #clientVideoWrapperInner {
        position: relative!important;
        max-height: 257px;
    }
}
@media (min-width: 1200px) {
    /*.containerClientVideoWrapper{
        height : 530px;
    }/**/
    .clientVideoWrapper{
        height : 530px;
    }
    #clientVideoWrapperInner {
        max-height: 525px;
        background: #333;
        background: radial-gradient(#777, #333);
        height: 696px;
    }
    #clientVideo {
        max-height: 526px;
        margin-top: -33px;
    }
}