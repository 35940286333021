#codexResourceList, #resourceList {
  padding-left: 0;
  padding-right: 0;
}

#codexResourceList > *, #resourceList > * {
  padding-left: 12px;
  padding-right: 12px;
}

.next-arrow-left, .next-arrow-right {
  height: 20px;
  width: auto;
  color: red;
}

.next-arrow-left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.list-group.codex-list-group .other-stat__item p {
    font-size: .95rem;
    font-weight: 600;
    line-height: 1.25rem;
}

.list-group.codex-list-group .list-group-item {
    position: relative;
    display: block;
    padding: 0.6rem;
    color: #212529;
    text-decoration: none;
    background-color: initial;
	border: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
	transition: color 0.3s;
}
.list-group.codex-list-group .list-group-item:hover {
	color: #253858;
   /* border-color: transparent;
	background-color: #ffffff;*/
}
.list-group.codex-list-group .list-group-item.active p {
    font-weight: 600;
	color:#253858;
}
.list-group.codex-list-group  {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
	width: 91%;
}
.row.CodexIdElement li a+ul {margin-bottom: 15px;}

#ResourceListTree .CodexIdElement > li a,
#ResourceListTree > li.col-md-12 > a {margin-bottom: 5px;display: inline-block;}

/*ul#ResourceListTree li > a {margin-bottom:10px;display: block;}*/

#ResourceListTree a.codexTreeFont.codex-link {
    color: var(--bs-body-color);
    font-weight: 500;
}
#ResourceListTree .codexTreeFont.codex-link:hover {
    text-decoration: underline;
	color: #253858;
}


	