.input-profile{
    height: 32px;
}
.textarea-profile{
    min-height: 100px;
}
.phone-input{
    width: 80%;
    height: 35px;
}
.phone-div{
    display: flex;
}
.container-profile{
    display: flex;
}
.container-inputs{
    width: 40%;
}