.containerWrap {
    margin: 120px 0;
    display: flex;
    justify-content: center;
}

.alert {
    border-radius: 4px;
    display: inline-block;
}

.btn {
    border: 1px solid #af2424;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px;
    gap: 4px;
    color: #af2424;
}

.btn:hover {
    background-color: #af242425
}

.btnPrimary {
    border-color: #084298;
    color:  #084298;
}

.btnPrimary:hover {
    background-color: #0842982f
}


.vidCont {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    width: 100%;
}
.vidCont video {
    aspect-ratio: 4/3;
    min-height: 200px;
    width: auto;
    max-width: 49%;
    background-color: gray;
    border-radius: 4px;
    flex-grow: 1;
}

.callCont {
    display: flex;
    flex-direction: column;
    align-items: center;
}