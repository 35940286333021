.call_interface_leftside_wrapper{
    height: 100%;
    width: 100%;
    background: #283039;
    color: #fff;
}

.call_interface_leftside_header {
    padding: 15px;
    color: #ccc;
}

.call_interface_leftside_companion_info{
    text-align: center;
    display: block;
    position: relative;
    background: #36404a;
    padding: 15px 0;
}

.call_interface_leftside_companion_avatar_width_overflow {
    border-radius: 50%;
    max-width: 300px;
    height: 300px;
    overflow: hidden;
    margin: 15px auto;
    text-align: center;
}

.call_interface_leftside_companion_avatar_img{
    margin: auto;
    max-width:300px;
    max-height: 300px;

}

.call_interface_leftside_companion_name {
    font-size: 32px;
}

.call_interface_leftside_call_info {
    padding: 30px 0;
    text-align: center;
}

.call_interface_leftside_call_btnspanel {
    padding: 15px;
    height: 70px;
}

.call_interface_leftside_companion_call_btn {
    min-width: 40px;
    height: 40px;
    border: solid 1px #ccc;
    border-radius: 20px;
    float: left;
    margin-left: 10px;
    text-align: center;
    line-height: 40px;
    font-size: 25px;
}

.call_interface_leftside_companion_call_btn_red{
    border: solid 1px #a00023;
    background:#a00023;
    color:#fff;
    cursor:pointer;
}

.call_interface_leftside_companion_call_btn_green{
    border: solid 1px #78b227;
    background:#78b227;
    color:#fff;
    cursor:pointer;
}

.call_interface_leftside_companion_call_btn:first-child {
    margin-left: 0;
}

.call_interface_leftside_companion_call_right_panel{
    float:right;
}

.call_interface_leftside_footer{
    background: #1d232b;
}

.call_interface_leftside_companion_call_eject{
    font-size: 18px;
    line-height: 38px;
    padding: 0 15px;
}

.call_interface_leftside_companion_call_eject>i{
    rotate: 135deg;
    margin-left: -4px;
}

.call_interface_leftside_companion_call_btn.call_interface_leftside_companion_avatar_width_overflow{
    font-size: 18px;
    line-height: 38px;
}

.call_interface_leftside_companion_call_btn>span{
    font-size: 18px;
    line-height: 10px;
}

.call_interface_leftside_companion_call_hd{
    font-size: 18px;
    line-height: 38px;
    padding: 0 10px;
}


/*callVideoBig*/
/*callVideoSmall*/

.playerVideoBig{
    height:400px;
}

#callVideoBig, .playerVideoBig {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    height: 100%;
    width: 100%;
    max-height: none;
    padding: 0;
    margin: 0;
    background: #36404a;
}
#callVideoSmall, .playerVideoSmall {
    width: 25%;
    position: absolute;
    left: 25px;
    bottom: 30px;
}
#pills-tabContent{
    height: calc(640px - 50px - 16px);
}

.CallListPager{
    height: calc(640px - 50px - 16px - 50px);
    overflow: hidden;
    overflow-y: scroll;
}

.CallListPager .img-thumbnail {
    height: 33px;
}

.VideoLoader_centerPage {
    position: absolute;
    top: 40%;
    left: calc(50% - 35px);
}