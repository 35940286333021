.situation-item-wrapper {
    max-width: 100%;
    line-height: 17px;
    border: solid 1px #ccc;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px!important;
}
.situation-description {
    background: rgb(230, 245, 251);
    border: 2px solid rgb(194, 235, 250);
    font-size: 1.1rem;
    line-height: 1.2;
    font-weight: 500;
    color: rgb(119, 119, 119);
    padding: 1rem;
    padding-bottom: 0;
	margin-left: 1px;
}
.situation-description p {
    padding: 0;
    padding-left: 0.3rem;
}


.wysiwig_content p, ul, li {
    font-size: 1rem;
    line-height: 24px;
    color: #000;
    margin-bottom: 10px;
}
.wysiwig_content ul {
    list-style: none inside;
}

ul.situation-list li.single-section-info {
    overflow: hidden;
    margin: 0;
    padding: 20px 0;
    border-top: 1px solid #dfdfdf;


    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
ul.situation-list li.single-section-info:last-child {
    margin-bottom: 0;
}
ul.situation-list li.single-section-info:first-child {
    border-top: 0;
}
ul.situation-list li.single-section-info .section-icon {
    float: left;
    text-align: center;
    overflow: hidden;
    margin-right: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul.situation-list li.single-section-info .section-info h3 {
    margin: 0 0 0.5em;
    font-size: 1.5rem;
}
