.StateListWrapper {
  display: block;
  -webkit-column-width: 20%;
          column-width: 20%;
  -webkit-column-count: 5;
          column-count: 5;
  -webkit-column-gap: 0;
          column-gap: 0;
  padding-left: 10px;
}

@media (max-width: 992px) {
  .StateListWrapper {
    -webkit-column-width: 25%;
            column-width: 25%;
    -webkit-column-count: 4;
            column-count: 4;
  }
}

@media (max-width: 768px) {
  .StateListWrapper {
    -webkit-column-width: 33%;
            column-width: 33%;
    -webkit-column-count: 3;
            column-count: 3;
  }
}

@media (max-width: 576px) {
  .StateListWrapper {
    -webkit-column-width: 50%;
            column-width: 50%;
    -webkit-column-count: 2;
            column-count: 2;
  }
}
