.resource-viewer-c {
  display: block;
  -webkit-column-width: 20%;
          column-width: 20%;
  -webkit-column-count: 5;
          column-count: 5;
  -webkit-column-gap: 0;
          column-gap: 0;
  padding-left: 10px;
}

.resource-viewer-c.btn.btn-biryza.btn-biryza-mod {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

@media (max-width: 992px) {
  .resource-viewer-c {
    -webkit-column-width: 33%;
            column-width: 33%;
    -webkit-column-count: 3;
            column-count: 3;
  }
}

@media (max-width: 768px) {
  .resource-viewer-c {
    -webkit-column-width: 50%;
            column-width: 50%;
    -webkit-column-count: 2;
            column-count: 2;
  }
}

@media (max-width: 576px) {
  .resource-viewer-c {
    -webkit-column-width: 100%;
            column-width: 100%;
    -webkit-column-count: 1;
            column-count: 1;
  }
}
