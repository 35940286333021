.top_menu ul.navbar-nav li.nav-item {
    margin-left: 20px;
}

.submenu li a:hover {
    color: #141414 !important;
}

@media screen and (max-width: 1198px) {
    .site-header .header-rightside-info .header-lk-btn {
        margin-right: 10px;
    }
}

@media screen and (min-width:771px) and (max-width: 1200px) {

    nav.site-header > div.container {
        display: grid !important;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
    }

    .top_menu {
        grid-row: 2;
        grid-column: 1 / span 2;
        padding-bottom: 10px;
    }

    .top_menu .navbar-nav {
        flex-direction: row;
        justify-content: center;
    }

    .top_menu .navbar-nav .nav-item:first-child {
        margin-left: 0;
    }
}

@media screen and (max-width: 767px) {
    .site-header .header-rightside-info {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}