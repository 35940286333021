.fieldset_flex{
    display: flex;
}
.title_docs_value{
    padding: .375rem .75rem;
    width: 50%;
}
.input_control{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.situation-description {
    background: rgb(230, 245, 251);
    border: 2px solid rgb(194, 235, 250);
    font-size: 1.1rem;
    line-height: 1.2;
    font-weight: 500;
    color: rgb(119, 119, 119);
    padding: 1rem;
    padding-bottom: 0;
	margin-left: 1px;
}
.situation-description p {
    padding: 0;
    padding-left: 0.3rem;
}