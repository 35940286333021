@media screen and (max-width: 768px) {
    .pulse-button-wrap {
        margin-top: 25px;
        min-height: 100px;
    }
    .pulse-button {
        top: auto;
        right: 50%;
        transform: translateX(50%);
    }
}


/*
.go_to_callpage_btn {
margin-right: 1.5em;
min-width: 160px;
}*/

.go_to_callpage_btn{
	height: 100%;
box-shadow: 0 0 0 3px #006dd4 inset;
color: #ffffff;
background-color: transparent;
border-radius:.25rem;
padding: 0;
-webkit-box-shadow: none !important;
box-shadow: none !important;
background: transparent !important;
-webkit-align-items: stretch;
-ms-flex-align: stretch;
align-items: stretch;
float: left;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-flex-flow: row nowrap;
-ms-flex-flow: row nowrap;
flex-flow: row nowrap;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
-webkit-align-content: flex-start;
-ms-flex-line-pack: start;
align-content: flex-start;
text-decoration: none;
-webkit-box-shadow: none;
box-shadow: none;
padding: 1em 3em;
-webkit-transition: all 300ms ease;
-moz-transition: all 300ms ease;
transition: all 300ms ease;
position: relative;
}

/*
.go_to_callpage_btn .go_to_callpage_icon {
  color: #ffffff;
  box-shadow: 0 0 0 3em #006dd4 inset;
  background-color: transparent;
border-radius: 0;
font-size: 1.6em;
padding: .7em .8em;
background: #B07C4B;
color: #fff;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
float: left;
margin: 0;
display: inline-block;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
line-height: 1;
padding-right: .5em;
max-width: 100%;
}

.go_to_callpage_btn .go_to_callpage_title {
color: #006dd4;
box-shadow: 0 0 0 1px #006dd4 inset;
border-radius: 0;
padding: 1.3em 1.7em;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
float:left;	
  position: relative;
}
*/




.go_to_callpage_btn .go_to_callpage_title {
    color: rgb(63, 63, 63);
    box-shadow: inset 0 0 0 3px #00baff;
    border-radius: 0;
    padding: .5em .5em;
    transition: all .3s ease;
    float: left;
    position: relative;
	display: inline-block;
	font-size: 1.35rem;
    min-width: 195px;
}

.go_to_callpage_btn .go_to_callpage_icon {
    box-shadow: inset 0 0 0 3em #00baff;
    /* background-color: initial; */
    border-radius: 0;
    font-size: 1.35rem;
    /* background: #b07c4b; */
    display: flex;
    margin: 0;
    display: inline-block;
    /* line-height: 1; */
    padding: .5em .5em .5em .55em;
    max-width: 100%;
}


a.go_to_callpage_btn {
 box-shadow:0 0 0 3px #00baff inset;
 color:#ffffff;
 background-color:transparent;
 
}
a.go_to_callpage_btn:hover {
 box-shadow:0 0 0 1em #00baff inset;
 color:rgb(63, 63, 63);
}




a.go_to_callpage_btn:hover .go_to_callpage_title {
 color: rgb(63, 63, 63);
 background-color: transparent;
 box-shadow: 0 0 0 1px #00baff inset;

}
a.go_to_callpage_btn .go_to_callpage_title {
 color:#ffffff;
 background-color:#00baff;
 box-shadow:0 0 0 3em #00baff inset;
}
/*
.go_to_callpage_btn .go_to_callpage_title::after, 
.go_to_callpage_btn .go_to_callpage_title::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
}*/