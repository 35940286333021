html,
body {
  /*background: #fafafb;/**/
  height: calc(100% - 100px); /* needed for container min-height */
  font: 1rem/1.5 var(--bs-font-sans-serif);
  /*z-index:-2;/**/
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
.mt-6 {
    margin-top: 4rem!important;
}
.form-group {
    margin-bottom: 1rem;
}
.phone-div {
    display: flex;
    margin-bottom: 5px;
}

.delete-contact {
    padding: 8px;
}

div#root {
    position: relative; /* needed for footer positioning*/
    /*height: auto;*/ /*!important; /* real browsers */
    min-height: 100%; /* real browsers */
    /*background: #fafafb;/**/
}

.qa-page{
      background: #fff;
      padding-bottom: 65px;
}

.codex-page {
    background: #fff;
    padding-top: 30px;
    min-height: 100%;
    padding-bottom: 65px;
}

#ResourceListC li {
    list-style-type: none;
}

.breadcrumbs ul {
    list-style-type: none;
    margin: 0 0 25px;
    padding: 0;
}

.breadcrumbs li {
    float: left;
    position: relative;
    display: block;
    width: auto;
	margin-bottom: 0;
}
/*
.breadcrumbs li a {
    font-size: 12px;
    font-weight: 700;
    color: #000;
}

.breadcrumbs li::before {
    content: " / ";
    font-size: 12px;
    margin:0 5px 0 10px;
    font-weight: 700;
}
*/
.breadcrumbs li, .breadcrumbs li a {
    color: #000;
    font-weight: normal;
    font-size: .846rem;
}
.breadcrumbs li a:hover {
    color: rgb(119, 119, 119);
}
.breadcrumbs li::before {
    content: " / ";
    font-size: .846rem;
    margin:0 5px 0 10px;
    font-weight: normal;
	color: #000;
}
.breadcrumbs span {
    color: rgb(119, 119, 119);
    font-size: .846rem;
}
.breadcrumbs li:nth-child(1)::before {
    content: " ";
    display: none;
}

#ResourceListTree {
    display: block;
    position: relative;
    flex: none;
    clear: both;
    margin-bottom: 40px;
    margin-top: 0;
}
.codexTreeFont *:empty{
    display: none;
}

.codexTreeFont p{
    display: block;
    position: relative;
    margin: 0;
}


svg#mapa path{
    cursor: pointer;
}
svg#mapa path:hover{
    fill: #366ca3;
    stroke: #333;
}

.situation-search-description {
    font-size: 17px;
    margin: 0 0 20px;
    padding: 0;
}

.features-title{
    font-size: 16px;
}


.google-logo{
    background: #fff url(/assets/images/google-logo.png) 100% 100% no-repeat;
}

.vk-logo{
    background: #fff url(/assets/images/vk-logo.png) 100% 100% no-repeat;
}
.service-login{
    font-size: 14px;
    color: #999;
}
.logo-social{
    background-size: cover;
    width: 40px;
    height: 40px;
    border: solid 1px #ccc;
    border-radius: 3px;
}
.codex-page, .page-chat {
    padding-top: 30px;
    min-height: 758px;
    padding-bottom: 65px;
}

#ResourceListTree li {
    list-style-type: none;
    margin-bottom: 2px;
}

/*LOADER ANIMATION BEGIN*/

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #00baff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .plenum-page, .page-panel, .page-situation, .page-is, .page-chat, .page-call, .page-docs, .page-priv-pol .page-about, .page-search, .qa-page{
    /*background: #fff;/**/
    padding-top: 30px;
    min-height: 100%;
    padding-bottom: 65px;
}

/*#accordion .collapse {
}/**/

#accordion .collapsing {
  border: solid 1px #eee;
  border-radius: 0 0 5px 5px;
  margin: 0 5px 5px;
  border-top: 0;
}
/*
#accordion-qa .collapse {
  border: solid 1px #ccc;
  border-radius: 0 0 5px 5px;
  margin: 0 5px 5px;
  border-top: 0;
}

#accordion-qa .collapsing {
  border: solid 1px #eee;
  border-radius: 0 0 5px 5px;
  margin: 0 5px 5px;
  border-top: 0;
}
*/
/*.page-situation input.situation-search-input {
  width: 100%;
  line-height: 42px;
  font-size: 32px;
}/**/

/*.situation-search-wrapper {
  position: relative;
  margin: 20px 0;
  padding: 30px 32px;
  background: #00baff;
  border-radius: 5px;
}*/

.container.quick-start {
    padding-bottom: 70px;
}

.reestr_itemlistbtn * {
  text-align: left;
}


/* NEWS BEGIN */

.img-preview{
  padding: 5px;
  width: 200px;
  height: 180px;
}
.img-preview img{
  width: 100%;
  height: 100%;
}
.news-element{
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #cccccc;
}
.news-preview-text{
  margin-top: 15px;
  padding-top: 10px;
  padding-left: 40px;
}
.news-title{
  padding-left: 40px;
}
.news-container{
  display: flex;
  flex-direction: column;
}
.news-more{
  display: flex;
  margin: auto;
}
.createdon_wrapper {
  margin: 0 0 15px;
  color: #aaa;
}
.createdon_wrapper.news_meta {
    font-size: 1rem;
    color: rgb(119, 119, 119);
    /*margin:0 !important;*/
}
/*.text-news p {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #000;
  margin-bottom: 30px;
}
*/
.text-news p {
    font-size: 1rem;
    /* font-weight: 300; */
    line-height: 24px;
    color: #000;
    margin-bottom: 10px;
}
.image-preview {
  margin-bottom: 40px;
}

.newslist_wrapper .newslist_title
{
  padding: 5px 5px 5px 20px;
}
.qa_wrapper .qa_title {
  padding: 5px 5px 5px 15px;
}


.newslist_wrapper .newslist_title h4,
.qa_wrapper .qa_title h4 {
  margin-top: 30px;
  color: #3f3f3f;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2; 
 /*border-bottom: 1px solid #f0f0f0; */ 
  margin: 0.75em 0 .5em 0;
}
.newslist_wrapper .newslist_title h4 span,
.qa_wrapper .qa_title h4 span{
  padding-bottom: 2px;
  border-bottom: 2px solid #00baff;  
}
.newslist_wrapper .news-list,
.qa_wrapper .qa-list
 {
  margin: 10px 0 5px 15px;
  border: solid 1px #ededed;
  padding: 10px 10px 15px 15px;
  border-radius: .25rem;
}
.qa_wrapper .qa-list
 {
  margin-left: 10px;
}
.newslist_wrapper .news-list .newslist_date {
  /*font-size: 12px;*/
  color:#777;
  font-size: 1rem;
}

.newslist_wrapper .news-list .news-item-title,
.qa_wrapper .qa-list .qa-item-title{
  /*font-size: 16px;*/
  font-size: 1.05rem; 
  font-weight: 500;
}
.qa-item-title.collapsed{cursor: pointer;}
.newslist_wrapper .news-list .news-item-description,
.qa_wrapper .qa-list .qa-item-description {
  /*font-size: 12px;*/
  font-size: 1rem;
}
.qa_wrapper .qa_title h4{
	margin-top: 0 !important;
	padding-top: 0 !important;
}
.qa_wrapper .qa-list .qa-item-description {
  /*font-size: 12px;*/
  margin-top: 15px;
}



/*
.newslist_wrapper .newslist_title h4 {
  border-bottom: 1px solid #f0f0f0;
  color: #3f3f3f;
  font-weight: 300;
  font-size: 1.385em;
}
.newslist_wrapper .newslist_title h4 {
  margin: 0.75em 0 .5em 0;
  line-height: 1.5em;
}
*/
.newslist_wrapper .newslist_title h4 span
.qa_wrapper .qa_title h4 span {
  display: inline-block;
  margin-bottom: -1px;
  padding: 8px 0;
  padding-bottom: 8px;
  border-bottom: 2px solid #00baff;
  border-bottom-width: 2px;
}


.news-item-date-month {
  font-size: 18px;
  font-weight: 500;
  margin-top: -5px;
}
.news-item-date-yaer {
  font-size: 18px;
}


/*.news-container {
  height: 650px!important;
}/**/

.news-container .login-form>span {
  font-weight: 400;
}

.newslist_comments_wrapper {
  margin-bottom: 20px;
}

.news-comment-item {
  margin: 6px 15px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 15px;
}

.news-comment-autor a {
  font-weight: 700;
  font-size: 16px;
}
.news-comment-autor {
  margin-bottom: 10px;
  float: left;
}

.news-comment-date {
  color: #999;
  font-size: 12px;
  float: right;
}
.news-comment-content {
  margin-left: 15px;
  font-size: 16px;
  margin-top: 15px;
  clear: left;
}

span.news-comment-time {
  margin-left: 10px;
  font-weight: 500;
}

 
 .news-wrapper {
  padding-top: 90px;
  padding-bottom: 60px;
}

.news-header-title {
  font-weight: 400;
  color: #fff;
}

.news-header {
  margin-bottom: 20px;
}

.news-item-image{
  height:250px;
  width:100%;
}

.news-item {
  position: relative;
  padding: 15px;
}

.newslist_wrapper .news-item-shield {
  position: absolute;
  bottom: 15px;
  padding: 0 10px 5px;
  width: calc(100% - 30px);
  margin: 0;
  background: rgba(255,255,255,.9);
}

.newslist_wrapper .news-item-title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin: 6px 0 12px;
}

.newslist_wrapper .news-item-description {
  font-size: 14px;
}

.newslist_wrapper .news-item-date {
  position: absolute;
  top: 0;
  right: 0;
  background: #00BAFF;
  color: #fff;
  font-size: 32px;
}

.newslist_wrapper .news-item-date-month {
  font-size: 18px;
  font-weight: 500;
}

.newslist_wrapper .news-item-date {
  position: absolute;
  top: 0;
  right: 0;
  background: #00BAFF;
  color: #fff;
  font-size: 32px;
  padding: 5px 13px;
  margin-right: 8%;
}
.news-wrapper .news-content-wrapper{
    margin-top: 0;
}



.commentlist-form {
  width: calc(100% - 30px);
  margin-left: -15px;
}

textarea.commentlist-form-textarea {
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin: 15px 0;
  padding: 10px;
}

textarea.commentlist-form-textarea:focus-visible{
  border: solid 1px #00baff;
}
/*
.commentlist_title h4 {
  font-size: 14px;
}
*/


.commentlist_title .newslist_title h4 {
  border-bottom: 1px solid #f0f0f0;
  color: #3f3f3f;
  font-weight: 300;
  font-size: 1.385em;
}
.commentlist_title .newslist_title h4 {
  margin: 0.75em 0 .5em 0;
  line-height: 1.5em;
}

.commentlist_title .newslist_title h4 span {
  margin-bottom: -1px;
  padding-bottom: 10px;
  border-bottom-width: 2px;
}
.commentlist_title .newslist_title h4 span {
  display: inline-block;
  margin-bottom: -1px;
  padding: 8px 0;
    padding-bottom: 8px;
  border-bottom: 2px solid #00baff;
    border-bottom-width: 2px;
}


/* NEWS END */




/* CHAT BEGIN */

.textarea_task_creater textarea {
  width: 80%;
  padding: 10px;
}

/*#createRoom, .createRoombtn {
  background: #00baff;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  width:100%;
}/**/

.textarea_task_creater {
  margin-bottom: 15px;
}

.room_title {
  padding: 15px;
}

.room_create_form textarea{
  padding:5px 10px;
  width: 100%;
}

.data-createdon{
  position: absolute;
  right: 8px;
  top: 5px;
  color: #000;
  font-size: 11px;
}

.room_info{
  position: relative;
}

.interlocutor_avatar {
  float: left;
  margin-bottom: 5px;
}
.message_createdon {
  position: absolute;
  top: -3px;
  right: 15px;
  font-size: 10px;
  color: #777;
}

#room_acceptFile{
  margin-bottom: 15px;
}

.room_list_title{
  color: #000;
  padding: 3px;
  margin-left: 30px;
  font-weight: 500;
  margin-right: 80px;
}

.room_list_item {
  box-shadow: none;
  border-bottom: solid 1px #dedede;
  margin: 0!important;
  padding-top: 10px!important;
  padding-bottom: 6px;
  cursor:pointer;
}

.room_list_item:hover {
  background: #caeffd;
}
.room_list_item.disabled_room {
  background: #f7f7f7!important;
  cursor: not-allowed;
}

.room_list_item.current_room {
  background: #04b6f6;
}
.current_room .room_list_title, 
.current_room .room_info span, 
.current_room .room_info small>i, 
.current_room .data-createdon {
  color: #fff;
}

.room_info small>i{ font-size: 0.85em; }

/* CHAT END */



/* SCROLL BEGIN */

html::-webkit-scrollbar, .scroll::-webkit-scrollbar {
  width: 4px;
}

html::-webkit-scrollbar-track, .scroll::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 3px rgba(1, 62, 133, 0.5); /**/
  border-radius: 3px;
  background:#fff;
}

.scroll::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb {
  border-radius: 3px;
  /*box-shadow: inset 0 0 3px rgba(1, 62, 133, 0.5); 
  -webkit-box-shadow: inset 0 0 3px rgba(1, 62, 133, 0.5); /**/
  background:#04b6f6;
}

#listMessageWrapper::-webkit-scrollbar-track {
  background: #f9f8ff;
}
/* SCROLL END */

.call_panel_wrapper {
  position: relative;
  min-height: 100px;
}

.call_panel_container {
  position: relative;
  /*border-radius: 65px;/**/
  background: #00baff;
  width: 100%;
  height: 130px;
}

.call_panel_image {
  position: absolute;
  bottom: 0;
  left: 60px;
}

.call_panel_image img {
  width: 310px;
}

.call_panel_title {
  position: absolute;
  left: 350px;
  top: 26px;
  font-size: 32px;
  color: #fff;
  font-weight: 600;
}

.call_panel_description {
  position: absolute;
  left: 575px;
  top: 55px;
  color: #fff;
  font-size: 16px;
}

.call_panel_buttons {
  position: absolute;
  right: 50px;
  top: 25px;
}

.call_panel_bgtransparent {
  left: 372px;
  position: absolute;
  top: 0;
  height: 130px;
  width: 300px;
  overflow: hidden;
}

.call_panel_bgtransparent .symbol_qwe {
  position: absolute;
  font-size: 230px;
  font-weight: 600;
  font-family: sans-serif;
  color: #fff;
  top: -65px;
  z-index: 0;
  opacity: 0.25;
}


/* DOCUMENTATION BEGIN */

.documentation-file-wrapper{
  display: block;
  position: relative;
  color: #222;
  margin-bottom:25px;
}

.documentation-file-wrapper .card-body{
  height: max-content;
  min-height: 270px;
  padding-bottom: 50px;
}

.documentation-file {
  margin: 0!important;
  color: #222;
  z-index: 1;
  position: relative;
}

.documentation-files::after {
  content: " ";
  clear: both;
}

.documentation-file-btn-description{
  display: block;
  position: relative;
  z-index: 9;
  color: #222;
}

.documentation-file-btn-icon{
  display: block;
  filter: invert(5%);
  float: right;
  right: -5px;
  bottom: 15px;
  position: absolute;
  z-index: 0;
  width: 150px;
}

.uk-accordion-title-wrapper {
  clear: both;
  width: 100%;
  position: relative;
  display: block;
}
.documentation-file a {
  font-size: 14px;
  color: #222;
  text-align: center;
  display: block;
  width: 100%;
}

.documentation-file img {
  margin: auto;
  width: 130px;
  display: block;
}

.documentation-files{
  padding-left: 0!important;
}

.documentation-file-bold{
  font-size: 18px;
  font-weight: 500;
}

.documentation-file-btn-panel{
  position:absolute;
  bottom:15px;
  left:15px;
  z-index: 1;
}
.documentation-file-wrapper .btn-biryza.btn-biryza-mod{
  margin-bottom: 0!important;
}

.documentation-file-wrapper:hover .btn-biryza.btn-biryza-mod{
  border: 1px solid #0077a3;
  background: #0077a3;
  color: #fff;
  text-decoration: none;
}

/* DOCUMENTATION END */


.right-border + .situations-list-container{padding-right: 20px;}


.situation-search-result .item_wrapper h3.blogpost_title {
  margin: 0;
}

.situation-search-result .item_wrapper {
  padding: 10px;
  border-bottom: solid 1px #eee;
  margin-right: 35px;
}

.situation_search_autocomplete div {
  background: #fff;
  padding: 5px;
  border-bottom: solid 1px #ccc;
  cursor: pointer;
}
.situation_search_autocomplete div:hover {
  background: #eee;
}

.situation_search_autocomplete {
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 9999999;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}
.situation_search_autocomplete:not(:empty){
  border:solid 1px #ccc;
}

.panel_container_item_wrapper {
  position: relative;
  width: 100%;
  height: 300px;
  background: #fff;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 35px;
  /*box-shadow: 0 0 11px 0  rgba(0, 0, 0, .3);/**/
  border: solid 1px #f0f0f0;
  overflow: hidden;
}

.page-situation .panel_container_item_wrapper,
.qa-page .panel_container_item_wrapper{
  height: 250px;
}

.panel_container_item {
  position: relative;
}

.panel_container_item_info {
  color: #000;
  position: absolute;
  bottom: 15px;
  right: 15px;
  left: 15px;
}

.panel_container_item_title {
  /*position: absolute;
  bottom: 20px;
  right: 20px;/**/
  z-index: 9;
  /*right: 20px;
   position: absolute; */
  /* bottom: 50px; */
  /* right: 0; */
  /* z-index: 9; */
  font-weight: 600;
  padding: 0 0 5px;
  color: #000;
  font-size: 18px;
  /* text-align: right; */
  /* line-height: 21px; */
  /* max-width: 95%;
  bottom: 20px; */
}

.all-search-wrapper, .situation-search-wrapper {
  padding: 1px 1px 1px 1px;
  margin-bottom: 45px;
  background: #00baff;
  border-radius: .25rem;
  border: 1px solid #0092c9;
 /* box-shadow: 0 0 11px 0px #0004;*/
}
.situation-search-wrapper .fa {font-size:1.5rem;}
.all-search-input, .situation-search-input {
  position: relative;
  width: calc(100% - 130px);
  line-height: 38px;
  font-size: 1.25rem;
  outline: none;
  padding-left: 55px;
  border: 0;
  padding-bottom: 5px;
  border-radius: 3px 0 0 3px;
  color: #777;
}

.all-search-input::placeholder, .situation-search-input::placeholder {
  color: #ddd;
}

.search_btn {
    width: 130px;
    /*height: 40px;*/
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 22px;
}
.situation-search-wrapper .search_btn {
  font-size: 1.17rem;
  font-weight: 600;
  height: auto !important;
}

.all-search-input::before, .situation-search-input::before{
  content:"\f002";
  font-size: 14px;
}

.all-search-result .item_wrapper h3.blogpost_title {
  margin: 0;
}

.all-search-result .item_wrapper {
  padding: 30px 32px;
  border-bottom: solid 1px #eee;
  margin-right: 35px;
}
 
.all_search_autocomplete div {
  background: #fff;
  padding: 5px;
  border-bottom: solid 1px #ccc;
  cursor: pointer;
}
.all_search_autocomplete div:hover {
  background: #eee;
}

.all_search_autocomplete {
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 9999999;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}
.all_search_autocomplete:not(:empty){
  border:solid 1px #ccc;
}

.all-search-wrapper .uk-search, .situation-search-wrapper .uk-search{
  position: relative;
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.02); }

.breadcrumb-search .breadcrumb-item{
  margin-right: 0;
}
.search_title a {
  color: #333;
  font-size: 18px;
  text-decoration: underline;
}
.search_title:hover a {
 text-decoration: none;
}

.breadcrumb-search * {
  font-size: 12px;
  line-height: 14px;
}

.breadcrumb-search .breadcrumb-item+.breadcrumb-item::before {
  padding-right: .2rem;
}
.breadcrumb-search .breadcrumb-item+.breadcrumb-item {
  padding-left: .2rem;
}

.reestr_itemlistbtn a {
  padding: 0;
}

.reestr_itemlistbtn {
  margin-bottom: 10px;
  height: 30px;
}

.reestr_itemlistbtn:hover a {
  text-decoration: underline;
}

.page-situation .breadcrumbs li, .plenum-page, .qa-page .breadcrumbs li, .page-is .breadcrumbs li  {
  float: left;
  position: relative;
  display: block;
  width: auto;
  font-size: 12px;
}

.all-search-wrapper label[for=search], .situation-search-wrapper label[for=search] {
  position: absolute;
  z-index: 9;
  font-size: 27px;
  margin-left: 8px;
  color: #cccc;
}

.filters .badge, .filters .badge:hover{
  color:#fff;
}

.uk-accordion-title {
  cursor: pointer;
}
.uk-accordion-title[aria-expanded=false] {
  color: #0077a3;
  background: #fff;
}
.uk-accordion-title[aria-expanded=true] {
  color: #fff;
  background: #0077a3;
}

.all-search-input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
.all-search-input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }

.all-search-input::-webkit-search-decoration,
.all-search-input::-webkit-search-cancel-button,
.all-search-input::-webkit-search-results-button,
.all-search-input::-webkit-search-results-decoration { display: none; }

  .situation-search-input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
  .situation-search-input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
  
  .situation-search-input::-webkit-search-decoration,
  .situation-search-input::-webkit-search-cancel-button,
  .situation-search-input::-webkit-search-results-button,
  .situation-search-input::-webkit-search-results-decoration { display: none; }


  .filter-item {
    color: #000;
    font-size: 16px;
    margin-right: 10px;
}

.filter-item-active{
  text-decoration: underline;
  font-weight: 700;
  
}

.btn.btn-biryza {
  border: 1px solid #00baff;
  padding: 2px 5px;
  width: max-content;
  text-align: center;
  font-weight: 600;
  height: 62px;
  line-height: 20px;
  box-shadow: 0 0 4px 0 #ccc;
  display: table-cell;
  color: #04b6f5;
}

.btn.btn-biryza:hover {
  border: 1px solid #04b6f5;
  background: #04b6f5;
  color: #fff;
  text-decoration: none;
}

.btn.btn-biryza.btn-biryza-mod{
  display: block!important;
  height: 30px!important; 
  width: max-content; 
  float: left!important; 
  margin-right: 5px!important; 
  margin-bottom: 5px!important;
  background: #fff;
  color: #04b6f5;
}

.btn.btn-biryza.btn-biryza-mod:hover {
  border: 1px solid #04b6f5;
  background: #04b6f5;
  color: #fff;
  text-decoration: none;
}

.btn.btn-biryza-light{
  border: 1px solid #00baff;
    padding: 2px 5px;
    text-align: center;
    font-weight: 600;
    line-height: 20px;
    box-shadow: 0 0 4px 0 #ccc;
    color: #04b6f5;
  }

.reestr_itemlist_wrapper {
  display: table;
  width: 100%;
  overflow: hidden; /* Fix for firefox and IE 10-11  */
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  break-inside: avoid-column;
}


/*.page-situation a.btn.btn-biryza, .page-situation a.btn.btn-biryza:hover {
  border: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  font-weight: 400;
  height: auto;
  line-height: auto;
  box-shadow: none;
  display: block;
  color: #333;
  background: #fff;
}/**/

.juristuser_item h5.card-title {
  font-size: 18px;
}

.juristuser_item h5.card-title a {
  color: #000;
  font-weight: 600;
}

.social_icon_item a {
  color: #0077a3;
  font-size: 40px;
  float: left;
  margin-right: 15px;
}

.link_icon_item a {
  color: #0077a3;
  font-weight: 600;
  font-size: 17px;
}


#send_message {
  border: 1px solid #04b6f5;
  position: relative;
  width: 20%;
  float: left;
  height: 60px;
  padding: 7px;
  margin-bottom: 5px;
  color: #fff;
  background: #04b6f5;
}
#send_message:hover {
  background: #fff;
  border: 1px solid #04b6f5;
  color: #04b6f5;
}

#textarea_message {
  border: 1px solid #04b6f5;
  position: relative;
  width: 80%;
  resize: none;
  float: left;
  height: 60px;
  margin-bottom: 5px;
  outline: none !important ;
}

#textarea_message:active, #textarea_message:focus-visible {
  border: solid 1px #ccc;
}

.listroom_toppanel {
  width: 100%;
  display: block;
  position: relative;
  height: 66px;
  border-bottom: solid 1px #eee;
  background: #f7f7f7;
  border-right: 1px solid rgb(238, 238, 238);
}

#listRoomTop .createRoombtn {
  border-radius: 50%;
  height: 25px!important;
  width: 25px;
  position: absolute;
  right: 10px;
  top: 21px;
  border: 0;
  background: transparent;
  box-shadow: none;
  font-size: 20px;
}

#listRoomTop .createRoombtn:hover {
  color: #333;
}


#appeal {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  height: 66px;
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
  padding: 18px 20px;
}

.message_form_panel {
  padding: 15px;
  background: #f7f7f7;
  border-top: solid 1px #eee;
  position: absolute;
  top: calc(72vh - 3px);
  width: calc(100% - 12px);
}

.outer_message .message_createdon {
  color: #333;
}

.outer_message .card-body, .inner_message .card-body {
  padding: 5px 10px;
}

.outer_message a { color: #333; text-decoration: underline;}
.outer_message a:hover { color: #000; text-decoration: none;}

.inner_message a { color: #333; text-decoration: underline;}
.inner_message a:hover { color: #000; text-decoration: none;}

.outer_message:first-child, .inner_message:first-child {
  margin-top: 15px;
}

.outer_message:last-child, .inner_message:last-child {
  margin-bottom: 15px!important;
}

form.room_create_form {
  margin: 15px;
}

textarea#room_title, textarea#room_message {
  padding: 10px;
  font-size: 18px;
  border: solid 1px #04b6f5;
  border-radius: 5px;
  outline: none !important ;
}

textarea#room_title:focus-visible, textarea#room_message:focus-visible {
  border: solid 1px #04b6f5;
  outline: none !important ;
}

textarea#room_title{
  overflow: hidden;
}

div#createRoom {
  padding: 13px;
  height: 50px!important;
  width: 100%;
}

.outer_message .thumbnail {
  width: 300px;
  height: 250px;
  overflow: hidden;
}
.outer_message .thumbnail img {
  min-width: 300px!important;
  min-height: 300px!important;
  width: auto!important;
  height: auto!important;
  max-width: auto!important;
  max-height: auto!important;
}

.room_list_item .card-body {
  padding: 0 30px;
  font-size: 12px;
  color: #777;
  height: 18px;
  overflow: hidden;
  margin-bottom: -3px!important;
}

.inner_message .card-title, .outer_message .card-title{
  font-size: 14px;
  font-weight: 700;
}

#listMessageWrapper{
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  display: block;
  margin-bottom: 0;
  background: #f9f8ff;
}


.is_advocat_accept{
  display: block !important;
  position: absolute;
  right: 5px;
  bottom: -8px;
  background: #fff;
}

.is_advocat_accepted{
  display: block !important;
  position: absolute;
  font-size: 20px;
  right: 21px;
  bottom: 3px;
  color: #34a600;
  background: #fff;
}


.callback-bt i {
  color: #fff;
  font-size: 34px;
  transition: .3s;
  line-height: 66px;
  transition: .5s ease-in-out;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#CallListouter {
  position: absolute;
  bottom: 10px;
  width: 150px;
  left: 10px;
}

.video_container:hover #VideoCallPause, .video_container:hover #VideoCallPlay {
  display:block;
}

#VideoCallPause, #VideoCallPlay {
  display:none;
  position: absolute;
  left: 40%;
  top: 40%;
  height: 20%;
  width: 20%;
  z-index: 99;
  font-size: 64px;
  text-align: center;
  opacity: .7;
  cursor:pointer;
}

#VideoCallPause:hover, #VideoCallPlay:hover {
  opacity: .95;
}

.video_container {
  position: relative;
  height: 400px;
  border: solid 1px #ccc;
  border-radius: 4px;
  margin-bottom:5px;
  background: #333;
  background: radial-gradient(#777,#333);
  overflow: hidden;
}

#VideoInnerObj {
  height: 400px;
}

.video_control{
  margin-bottom:15px;
}

.news-comment-autor a {
  color: #000;
}



.phone-div select {
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  margin-left: -5px;
}

.avatarimagecontainer_profilepage{
  width: auto;
  min-height: 300px;
  padding: 3px;
  margin: auto;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}



.left-border {
  border-left: solid 1px #eee;
}

.right-border {
  border-right: solid 1px #eee;
}

.pulse-button__icon {
  display: block;
  /*width: 30px;
  height: 30px;
  background: url("../img/phone.svg") center center/cover no-repeat;/**/
  transition: .3s;
  margin-top: -13px;
  font-size: 58px;
  color: #fff;
}

/*
.pulse-button:hover .pulse-button__icon {
  opacity: 0;
  visibility: hidden;
  position: relative;
  transition: .3s;
}
 
.pulse-button:hover .pulse-button__text {
  transition: .3s;
  opacity: 1;
  visibility: visible;
  position: relative;
}/**/

.pulse-button__rings {
  border: 1px solid #ff0000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  border-radius: 50%;
  animation-duration: 2.0s;
  animation-name: pulse_1;
  animation-iteration-count: infinite;
  z-index: -1;
}
 
.pulse-button__rings:nth-child(2) {
  animation-name: pulse_2;
}
 
.pulse-button__rings:nth-child(3) {
  animation-name: pulse_3;
}
.pulse-button__text {
  display: block;
  width: 100%;
  height: 30px;
  font: 12px 'Open Sans', sans-serif;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: .3s;
  margin-top: 1px;
}

.pulse-button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  padding: 10px;
  border: none;
  background: #ff0000;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 30px;
  right: 100px;
  cursor: pointer;
  outline: none;
  z-index: 9;
}

@keyframes pulse_1 {
  from {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }
 
  to {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}
 
@keyframes pulse_2 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }
 
  to {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}
 
@keyframes pulse_3 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }
 
  to {
    opacity: 0;
    transform: scale(1.7, 1.7);
  }
}
/**/
/*
.panel_container_item .panel_container_item_description {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  padding: 20px;
  background: #0009;
  color: #000;
  -webkit-transition: bottom .5s;
  transition: bottom .5s;
}/**/
/*
.panel_container_item:hover .panel_container_item_description {
  bottom: 0;
  -webkit-transition: bottom .5s;
  transition: bottom .5s;
}
.panel_container_item .panel_container_item_title {
  opacity: 1;
}

.panel_container_item:hover .panel_container_item_title {
  opacity: 0;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}/**/

.call_dash_panel.text-center {
  position: absolute;
  bottom: 50px;
}

.call_dash_panel *{
  color:#000;
}

.call-microphone-switcher {
  margin: 0 15px;
}

.callback-bt {
  border-radius: 50%!important;
  border: solid 1px;
}

.fas-slashed {
  border-top: 4px solid #fff;
  border-bottom: 4px solid #005b7d;
  position: absolute;
  top: 28px;
  width: 42px;
  left: -3px;
  height: 0;
  -webkit-transform: rotate(-12deg);
  transform: rotate(-47deg);
}

.mobile_burger_panel_btn{
  display:none;
  float:left;
  margin-left: 40px;
  border: solid 1px #fff;
  padding: 2px 10px;
  margin-top: 5px;
  color:#fff;
}
.mobile_burger_panel_btn i{
  color:#fff;
}


div#wrapper_dial_call_infinity {
  position: relative;
  text-align: center;
  padding-top: 185px;
}

#wrapper_dial_call_infinity .lds-ellipsis {
  width: 100px;
}

@media (max-width: 768px) {
  .mobile_burger_panel_btn{
    display:block;
  }
  .site-header .top_menu {
    display: none;
    position: absolute;
    top: 40px;
    background: #000;
    left: 0;
    right: 0;
    z-index: 9;
  }
  .site-header .top_menu .nav-item a::before {
    content:" - ";
  }
  .site-header .top_menu .nav-item a {
    padding-top: 10px;
    height: 40px;
    display: block;
    color: #fff;
  }
  nav.site-header a.logo {
    float: left;
  }
  .site-header .top_menu .nav-item {
    min-height: 40px;
  }
  nav.site-header a.logo img {
    height: 40px;
  }
  nav.site-header a.logo .logo_text {
    font-size: 20px;
    line-height: 40px;
  }

  .h1.b1_title, .h1.b2_title{
    font-size: 48px;
  }
  .top_menu .submenu {
    position: relative;
  }
  .top_menu .submenu ul {
    position: relative;
    border-radius: 0;
    background: transparent;
  }
  #root .qa-page .news-container .card {
    margin-top: 0!important;
  }
  div#wrapper_dial_call_infinity {
      padding-top: 15px;
  }
}

.btn.btn-biryza.yook-create-payment, .btn.btn-biryza.yook-close-payment-form {
  height: 32px;
  line-height: 27px;
  padding: 0 20px;
  width: auto;
}

.page-description__mobile{display:none}@media screen and (max-width: 992px){.page-description__mobile{display:block}.page-description__desktop{display:none}}

.codexCollapsedList > * {
  padding-left: 12px;
  padding-right: 12px;
}



div#payment_id {
    float: left;
    margin-top: 8px;
    margin-left: 30px;
}


.user_account_type img {
  width: 50px;
  position: absolute;
  top: 15px;
  right: 15px;
}

img.user_account_type_gray {
  filter: grayscale(100%) brightness(1.6);
  opacity: .5;
}

.user_account_type_gray.advocat_user{
  top: 77px;
  filter: grayscale(100%) brightness(1.2);
  opacity: .8;
}

img.user_account_type_gray:hover {
  filter: none;
  opacity: 1;
}

#price_sale_1,#price_sale_2,#price_sale_3,#price_sale_4{
  margin: 15px 15px!important;
}

.pay_page .btn.btn-biryza {
    position: absolute;
    bottom: 0;
    margin: 0 0 15px 30px;
    padding: 0;
    width: calc(100% - 90px);
    height: 40px;
    line-height: 34px;
}

.price_sale_name {
  font-size: 24px;
  color: #000;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 4px solid #00baff;
  margin-left: 30px;
  margin-right: 30px;
}

.price_sale_price {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #000;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
}

.price_sale_repeat .card {
  background: #e6f5fb;
  border: 2px solid #c2ebfa;
}

.profile_left_menu_item i{
  width:25px;
  text-align: center;
}

.yook-close-payment-form{
  width:150px!important;
  margin: 20px auto;
}

.yook_pay_informer {
  text-align: center;
  margin-top: 40px;
  font-size: 22px;
  margin-bottom: 30px;
}

.profile_left_menu_item i {
  width: 45px;
  text-align: center;
}

.profile_left_menu_item {
  height: 40px!important;
  line-height: 36px!important;
  border: 0!important;
  box-shadow: none!important;
  font-size: 20px!important;
  display: block!important;
  text-align: left!important;
  position: relative!important;
  margin: 0 0 10px!important;
}

.ThemeLocal__StyledThemeLocal-sc-1y2ad8h-0.kwzLJJ{
  display:none;
}

.payment-form-yook-close .btn.btn-biryza.yook-close-payment-form{
  position: relative;
  display: block;
  margin: auto;
  width: 460px!important;
  padding: 23px!important;
  height: 90px;
  border: solid 1px #ccc;
  box-shadow: 0 0 14px -3px #ccc!important;
  border-radius: 9px;
  text-align: left;
  transition: margin-top .3s, margin-bottom .3s;
  -webkit-transition: margin-top .3s, margin-bottom .3s;
}

.payment-form-yook-close .btn.btn-biryza.yook-close-payment-form:hover {
  transition: margin-top .3s, margin-bottom .3s;
  -webkit-transition: margin-top .3s, margin-bottom .3s;
  margin-top: -3px;
  margin-bottom: 3px;
  background: #fff;
  color: #0077a3;
  box-shadow: 0 3px 18px 0 #ccc!important;
}

.yook-close-payment-form i{
  border: solid 1px;
  padding: 12px;
  border-radius: 50%;
}

.go_back_to_select_tarif {
  color: #0a2540;
  font-size: 18px;
  font-weight: 500;
  margin-left: 12px;
  line-height: 40px;
}

.payment_item_panel .btn {
  margin-left: 10px;
  height: 20px;
  margin-top: 3px;
  line-height: 10px;
  font-size: 10px;
}

.count_of_premium_days{
  margin-bottom: 15px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.bg_callbtn{
  width: 235px;
  background: rgb(212, 241, 251);
  padding: 15px 15px 0;
  border-radius: 15px;
}
/*
.go_to_callpage_btn {
  position: absolute;
  background: #00baff;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  right: 25px;
  bottom: 90px;
  padding: 10px 0;
  border-radius: 30px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
  transition: box-shadow .2s, bottom .2s;
  -webkit-transition: box-shadow .2s, bottom .2s;
}


.go_to_callpage_btn:hover {
  bottom: 95px;
  color:#fff;
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 20%);
  transition: box-shadow .2s, bottom .2s;
  -webkit-transition: box-shadow .2s, bottom .2s;
}
*/

@media (max-width: 768px) {
 /* 
  .go_to_callpage_btn {
    width: 81%;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 25px;
  }

  .go_to_callpage_btn:hover {
    bottom: 5px;
  }
*/
  .search_btn {
    width: 90px;
  }
  .all-search-input, .situation-search-input {
    width: calc(100% - 90px);
  }

  .go_to_call_page_description h2 {
    text-align: center;
    font-size: 22px;
  }

  .go_to_call_page_description h4 {
    text-align: center;
    font-size: 16px;
  }

  
  body .need_a_help .bg_callbtn.bg_btn_male {
    margin-bottom: -25px;
  }
}

 .oul-courusel-wrapper .title {
    margin-bottom: 20px;  
    padding:20px  
  }
  .oul-courusel-wrapper .img {
    width:100%  
  }

  .oul-courusel-wrapper .carousel-caption {
    bottom: -10px;
  }
.oul-courusel-wrapper .carousel-caption {
  text-align: left;
  left: 15px;
  right: 15px;
}

img.panel_container_item_image {
  width: 120px;
}

.preset-content-item {
  margin-bottom: 25px;
}

.presets-content-wrapper .preset-content-item img{
  width:100%;
}

.preset-content-item a {
  position: relative;
  display: block;
}

.preset-content-item .carousel-caption {
  bottom: 0;
  padding: 0;
  right: 15px;
  left: 15px;
  text-align: left;
}

.read_more_on_panel{
  color:#00baff;
}

.read_more_on_panel:hover{
  color:#1d7497;
}

.StateListWrapper .reestr_itemlistbtn{
  height: 57px;
}

.oul-courusel-wrapper .owl-carousel .owl-item img, .presets-content-wrapper .preset-content-item img {
  border-radius: 30px;
}

.oul-courusel-wrapper .owl-carousel .owl-stage-outer{
  padding-bottom: 5px;
}

.page {
  background: #fafafb;
  /*height: 100%;*/
  padding-bottom: 90px; 
  
  
  /*min-height: 916px;/**/
}

.need_a_help .bg_callbtn.bg_btn_male {
  background: transparent;
  width: 340px;
  margin-top: -70px;
  margin-left: -70px;
  margin-bottom: -40px;
}
/*
.need_a_help .go_to_callpage_btn {
  bottom: 55px;
  width: 300px;
}

.need_a_help .go_to_callpage_btn:hover {
  bottom: 60px;
}
*/
.phone_image_on_need_a_halp {
  width: 20px;
  margin-top: -5px;
  margin-left: 10px;
}

.can_consult img.bg_callbtn {
  width: 100%;
}

.nav-item svg {
  fill: #fff;
  height: 15px;
  margin-top: -5px;
}
.nav-item:hover svg {
  fill: #ccc;
}

.add-button {
  position: absolute;
  top: 20%;
  left: calc(50% - 108px);
  z-index: 99999;
  background: #3fa9f5;
  color: #fff;
  border: solid 1px #fff;
  font-size: 30px;
  border-radius: 50px;
  width: 216px;
  padding: 5px 25px;
}

.panel_container_item_description {
  max-height: 85px;
}

.page-chat{
  margin-top: 100px;
  padding-bottom: 0;
  padding-top: 0;
}

.message-item .message-item__content .message-item__image-wrap {
    height: 200px;
    margin-bottom:20px;
}

.message-item.user a{
  color:#fff;
}

.message-item .message-item__content {
  box-shadow: none;
  position: relative;
  background-color: #fff;
  border-radius: 15px 0 15px 15px;
}
.message-item .message-item__content.user {
    position: relative;
    background-color: #04B6F5;
}

.message-item__content small.message-item__time {
  position: absolute;
  color: #333;
  bottom: -20px;
}

.message-item__content.user small.message-item__time {
  right:10px;
}

.message-item{
  margin-bottom: 25px;
}

.date-badge-c{
  margin-top: 40px;
  background: none;
}

.message-list{
  background: #FAFAFB;
  max-height: 65vh;
}

#listRoomWrapper{
  position: relative;
}

#listRoomWrapper::-webkit-scrollbar {
  width: 4px;
}

#listRoomWrapper::-webkit-scrollbar-button {
  visibility: none;
}

#listRoomWrapper::-webkit-scrollbar-thumb {
  background-color: #04b6f6;
  border-radius: 100px;
}

#listRoomWrapper::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: #fafafb;
}

.right-side-message-list {
  position: relative;
}

.message-item .message-item__content.user .message-item__text {
  color: #fff;
}

.message-item .message-item__content .message-item__image-wrap .message-item__image{
  margin-top:15px;
  border-radius: 0;
}

.message-item .message-item__content .message-item__time {
  color: rgba(34, 34, 34, 0.2);
  font-size: 11px;
  font-weight: 800;
}

.textPlaceholder {
  background: #ccc;
  color: transparent;
}
.message-item__content.user .textPlaceholder {
  background: #fff;
  color: transparent;
}

.panel_preset_item div p, .preset-content-item div p {
  max-height: 125px;
  overflow: hidden;
  margin-bottom: 0;
}



.news-more {
  margin-bottom: 10px;
}


.call-new {
  margin-top: 40px;
  padding: 20px;
  background: #2b3a4c;
}

.call-new ._callbtn_ecqgq_1 {
  background: green;
  width: auto;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 50px;
  margin-top:15px;
}

.self-center button {
  background: #04b6f5;
  border: none;
  margin-top: 30px;
  padding: 10px 15px;
  border-radius: 50px;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
}

#panelContainer2 .panel_container_item_wrapper {
	background: #fff;
	padding: 4px;
	border-radius: 35px;
	border: 1px solid #f0f0f0;
	overflow: hidden;
	display: block;
	padding: 4px;
	min-height: 230px;
}
#panelContainer2 img.panel_container_item_image {
	width: 100%;
	height: 120px;
	margin-bottom: 20px;
	object-fit: contain;
	object-position: center;
}
#panelContainer2.panel_container_item_info {
	color: #000;
	margin-bottom: 1rem;
	margin-top: 1rem;
	display: inline-block;
	padding-left: 19px;
	padding-right: 19px;
	line-height: 1.4;
}


/* блок Наши услуги */
.grid_container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 30px;
    color: #000;
}
.grid_container.grid_container-main {
    margin-left: 1px;
    margin-right: 3px;	
}
.grid_container.grid_container-3-4 {
    grid-template-columns: repeat(3,1fr);
    margin-left: 5px;
    margin-right: 2px;
    margin-top: 30px;	
}
.grid_container.grid_container-news {
    grid-template-columns: repeat(3,1fr);
}
@media (min-width: 640px) and (max-width: 1199.98px) {
	.grid_container {
	    grid-template-columns: repeat(3,1fr);
	}
    .grid_container.grid_container-3-4 {
        grid-template-columns: repeat(2,1fr);
    }
	.grid_container.grid_container-news {
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width: 639px) {
	.grid_container {	
	    grid-template-columns: repeat(2,1fr);
	}
    .grid_container.grid_container-3-4 {
        grid-template-columns: repeat(1,1fr);
    }
	.grid_container.grid_container-news {
        grid-template-columns: repeat(1,1fr);
    }
}


.grid_container-news .go_to_callpage_btn {
    position: relative;
    display: grid!important;
    text-align: center;
    box-shadow: inset 0 0 0 3px #006dd4;
    color: #fff;
    background-color: initial;
    box-shadow: none!important;
    background: transparent!important;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    text-decoration: none;
    box-shadow: none;
    padding: 1em 1em;
    transition: all .3s ease;
    position: relative;
    margin: 0 auto;
	width: 100%;
}

.grid_container-news .go_to_callpage_btn .go_to_callpage_title {
    color: #fff;
    background-color: #00baff;
    box-shadow: inset 0 0 0 3em #00baff;
}
.grid_container-news .go_to_callpage_btn .go_to_callpage_title {
    color: #FFF;
    box-shadow: inset 0 0 0 3px #00baff;
    border-radius: 0;
    padding: .4em;
    transition: all .3s ease;
    /*float: left;/**/
    position: relative;
    display: inline-block;
    font-size: 1.25rem;
    /* min-width: 195px; */
}
.grid_container-news .go_to_callpage_btn:hover {
 box-shadow:0 0 0 1em #00baff inset;
 color:rgb(63, 63, 63);
}



.presets-page .grid_container {margin-left: 1px; margin-right: 1px;}



.grid_container .grid_container_item {
    position: relative;
    display: block;
    height: 100%;
    min-height: 200px;
    color: #000;
    margin-bottom: 20px;
    border-radius: .25rem;
    border: 2px solid #f0f0f0;
    overflow: hidden;
    padding-top: 15px;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 5px;	
}
.grid_container .grid_container_item:hover {
    border-color: #c2ebfa;
    border-width: 2px;
    background-color: rgb(230, 245, 251);
}
.grid_container img.grid_container_item_image {
    height: 84px/*120px*/;
    width: 100%;
    margin-bottom: 20px;
    object-fit: contain;
    object-position: left;
}
.grid_container .grid_container_item_title {
    font-weight: 600;
    padding: 0 19px 5px;
	padding: 4px;
	padding-top: 0;
    color: #000;
    font-size: 18px;
    display: inline-block;
    line-height: 1.4;
}
.grid_container .grid_container_item_description {
	padding: 4px;
	padding-bottom: 0;
	color: #000;
}




.grid_container .grid_container_preset-item {
    position: relative;
    display: block;
    height: 100%;
    min-height: 200px;
    color: #000;
    margin-bottom: 20px;
    border-radius: .25rem;
    border: 2px solid rgba(0,0,0,.125);
    overflow: hidden;
    padding-top: 15px;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 10px;	
}
/*.grid_container .grid_container_preset-item:hover {
    border-color: rgba(0,0,0,.125);
    border-width: 2px;
    background-color: rgb(230, 245, 251);
}*/
.grid_container .grid_container_preset-item .preset-item-title {
    font-weight: 600;
    padding: 0 19px 5px;
	padding: 4px;
	padding-top: 0;
    color: #000;
/*    font-size: 18px;*/
    display: inline-block;
    line-height: 1.4;
    font-size: 1.05em;

}
.grid_container .grid_container_preset-item .preset-item-description {
	padding: 4px;
	padding-bottom: 0;
	color: #000;
}


/* блок Новости */
.grid_container .grid_container_news-item {
    position: relative;
    display: block;
    height: 100%;
    min-height: 300px;
    color: #000;
    margin-bottom: 20px;
    padding: 0;
    padding-top: 15px;
}

.grid_container .grid_container_news-item .news-item-shield {
    padding: 0;
    width: 100%;
    margin: 0;
}

.grid_container .grid_container_news-item .news-item-shield h4 {
    margin: 0.3rem 0 0.5rem;
    line-height: 1;
}

.grid_container .grid_container_news-item .news-item-shield .news-item-title {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}
.grid_container .grid_container_news-item .news-item-description {
    font-size: 1rem;
	padding-top: 4px;
}
.grid_container .grid_container_news-item .news-item-date {
    position: absolute;
    top: 0;
    right: 0;
    background: #00baff;
    color: #fff;
    font-size: 2rem !important;
    padding: 5px 13px;
    margin-right: 8%;
}
.grid_container .grid_container_news-item .news-item-date-month {
    font-size: 1.1rem !important;
    font-weight: 500;
    margin-top: -5px;
}
.grid_container .grid_container_news-item .news-item-date-yaer {
  font-size: 1.1rem !important;
}



.panel_preset_item {
    position: relative; 
    display: block; 
	height: 100%; 
	background-image: url("/assets/images/bgs/preset_bg.png"); 
    background-size: cover; 
	border-radius: .25rem; 
    padding: 1.05rem 1.25rem 0.5rem;
	z-index:20;
	opacity: .91;
}
.panel_preset_item:hover{opacity: 1;}


.page_preset_container{
	color: #000;
	padding:0;
	margin: 0;
}
.page_preset_item-inner {
    position: relative;
    display: block;
    height: 100%;
    border-radius: 0.25rem;
    padding: 1.05rem 1.25rem 0.5rem;
    z-index: 20;
    opacity: .91;
    /*background-color: rgb(230, 245, 251);*/ /*steelblue;*/
	border: 1px solid rgba(0,0,0,.125) /*2px solid #00baff*/ /*#C2EBFA*/;
}
.page_preset_item-inner:hover{opacity: 1; border: 0; background-color: #fff;}
.page_preset_item-inner.iconbox-hover-moving-border {
  overflow: hidden;
}
.page_preset_item-inner.iconbox-hover-moving-border:after, 
.page_preset_item-inner.iconbox-hover-moving-border:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  opacity: 0;
  transition: opacity 0.55s, transform 0.55s;
  z-index: -1;
}
.page_preset_item-inner.iconbox-hover-moving-border:after {
  border-right: 2px solid #326e89;
  border-left: 2px solid #326e89;
}
.page_preset_item-inner.iconbox-hover-moving-border:after {
  transform: scale(1, 0);
}
.page_preset_item-inner.iconbox-hover-moving-border:before {
  border-top: 2px solid #326e89;
  border-bottom: 2px solid #326e89;
}
.page_preset_item-inner.iconbox-hover-moving-border:before {
  transform: scale(0, 1);
}
.page_preset_item-inner.iconbox-hover-moving-border:hover:before, 
.page_preset_item-inner.iconbox-hover-moving-border:hover:after {
  opacity: 1;
  transform: scale(1);
}


.page_preset_item-inner .preset-item-title {
    font-size: 1.05em;
	font-weight: 600;	
}

.page_preset_item-inner div p {
    max-height: 125px;
    overflow: hidden;
    margin-bottom: 0;
}


.panel_preset_item.iconbox-style7-hover-moving-border {
  overflow: hidden;
}
.panel_preset_item.iconbox-style7-hover-moving-border:after, 
.panel_preset_item.iconbox-style7-hover-moving-border:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  opacity: 0;
  transition: opacity 0.55s, transform 0.55s;
  z-index: -1;
}
.panel_preset_item.iconbox-style7-hover-moving-border:after {
  border-right: 2px solid #326e89;
  border-left: 2px solid #326e89;
}
.panel_preset_item.iconbox-style7-hover-moving-border:after {
  transform: scale(1, 0);
}
.panel_preset_item.iconbox-style7-hover-moving-border:before {
  border-top: 2px solid #326e89;
  border-bottom: 2px solid #326e89;
}
.panel_preset_item.iconbox-style7-hover-moving-border:before {
  transform: scale(0, 1);
}
.panel_preset_item.iconbox-style7-hover-moving-border:hover:before, 
.panel_preset_item.iconbox-style7-hover-moving-border:hover:after {
  opacity: 1;
  transform: scale(1);
}











.panel_preset_item-inner.iconbox-style7-hover-moving-border {
  overflow: hidden;
}
.panel_preset_item-inner.iconbox-style7-hover-moving-border:after, 
.panel_preset_item-inner.iconbox-style7-hover-moving-border:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  opacity: 0;
  transition: opacity 0.55s, transform 0.55s;
  z-index: -1;
}
.panel_preset_item-inner.iconbox-style7-hover-moving-border:after {
  border-right: 2px solid #326e89;
  border-left: 2px solid #326e89;
}
.panel_preset_item-inner.iconbox-style7-hover-moving-border:after {
  transform: scale(1, 0);
}
.panel_preset_item-inner.iconbox-style7-hover-moving-border:before {
  border-top: 2px solid #326e89;
  border-bottom: 2px solid #326e89;
}
.panel_preset_item-inner.iconbox-style7-hover-moving-border:before {
  transform: scale(0, 1);
}
.panel_preset_item-inner.iconbox-style7-hover-moving-border:hover:before, 
.panel_preset_item-inner.iconbox-style7-hover-moving-border:hover:after {
  opacity: 1;
  transform: scale(1);
}







.grid_container_item.iconbox-hover-moving-border {
  overflow: hidden;
}
.grid_container_item.iconbox-hover-moving-border:after, 
.grid_container_item.iconbox-hover-moving-border:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  opacity: 0;
  transition: opacity 0.55s, transform 0.55s;
  z-index: -1;
}
.grid_container_item.iconbox-hover-moving-border:after {
  border-right: 2px solid #326e89;
  border-left: 2px solid #326e89;
}
.grid_container_item.iconbox-hover-moving-border:after {
  transform: scale(1, 0);
}
.grid_container_item.iconbox-hover-moving-border:before {
  border-top: 2px solid #326e89;
  border-bottom: 2px solid #326e89;
}
.grid_container_item.iconbox-hover-moving-border:before {
  transform: scale(0, 1);
}
.grid_container_item.iconbox-hover-moving-border:hover:before, 
.grid_container_item.iconbox-hover-moving-border:hover:after {
  opacity: 1;
  transform: scale(1);
}



/* situation list */
ul.situation-list {margin: -1.5em 0 0; padding: 0; list-style: none;}
ul.situation-list li {overflow: hidden; margin: 0; padding: 20px 0; border-top: 1px solid #dfdfdf;}
ul.situation-list li:first-child {border-top: none;}
ul.situation-list li img {float: left;width: auto;margin-right: 25px;height: 85px;}
ul.situation-list li div {overflow: hidden;}
ul.situation-list li h3 {margin: 0 0 0.5em; font-size: 1.5rem;}
ul.situation-list li h3 a {color: #3f3f3f;}
/*ul.situation-list li h3 a:hover {color: #04b6f5;}*/
/*ul.situation-list li h3:hover, ul.situation-list li h3 a:hover, ul.situation-list li p:hover {color: #04b6f5;}*/



/* preset item */
.callout {
    padding: 12px 20px;
    margin: 20px 0;
    border: 1px solid rgba(0,0,0,.125);
    border-top-width: 2px;
    border-radius: 2px;
    box-shadow: 0 1px 5px 0 rgba(50,50,50,.05);
}

.callout h4,.callout h5 {
    margin: 16px 0;
}
.callout:hover {background-color: #fff;}
.callout-info {
    border-top-color: #00baff;
    transition: all .2s ease-in-out;
}
.callout-info .card-body {margin-top:0; padding-top: 0;}
.callout .card-body h4,
.callout .card-body h5
{margin-top:0; padding-top: 0;}

.callout-info p:last-child {
    margin-bottom: 0;
    font-size: 1rem;
}
.callout-info a,.callout-info h4,.callout-info h5 {
    color: #253858;
}
.callout-info h4,.callout-info h5 {
    /*! text-transform: uppercase */
    font-size: 1.5rem; /*calc(1rem + .9vw);*/
}
@media (max-width: 767px) {
    .callout {
        padding: 10px;
    }
}
ul.invent-list-square {
	list-style: none;
    margin-left:0;
    padding-left:0;
/*! margin-bottom: 30px; */
}
ul.invent-list-square li{padding-top:7px;margin-left: 20px; font-size: 1rem;}
ul.invent-list-square li a {color: var(--bs-body-color); text-decoration: none;}
ul.invent-list-square li a:hover {color: #04b6f5; text-decoration: underline;}
ul.invent-list-square li::before {
	content: "\25A0";
	display: inline-block;
	margin-left: -20px;
	width: 20px;
	font-size: 12px;
	color: #00baff;
}
          
		  
		  
		  
		  
		  
.single_service_card {
  position: relative;
  background-color: #FFF;
  /* border-radius: 6px; */
}
.single_service_card h4 {
  color: #253858;
  padding: 30px 0px 5px 20px;
  margin-bottom: 5px;
}
.single_service_card p {
  /*color: #666666;*/
  padding: 0px 20px;
  margin-bottom: 20px;
font-size: 1rem;
}
.single_service_card a {
  text-decoration: none;
  color: #253858;
  margin: 0px 20px;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #253858;
}

.single_service_card a:hover {
  /* color: #B69D74; */
  text-decoration: none;
  border: 0;
  margin-bottom: 21px;
}
.single_service_card .single_img {
  /* z-index: 2; */
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 6px 500px 6px 6px;
  overflow: hidden;
}
.single_service_card .single_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.single_service_card .jus_element {
  z-index: 1;
  position: absolute;
  top: calc(40% + 10px);
  right: 0;
}









































/* ----------------------------------
	About section Styles
 ------------------------------------ */
.about_wrapper {
  position: relative;
}
.about_wrapper .about_element {
  position: absolute;
  bottom: 0%;
  right: 0%;
  z-index: -1;
}
.about_wrapper .about_content .single_author {width: 150px;}
.about_wrapper .about_content .author_name,
.about_wrapper .about_content a.btnd
 {margin-top: 1.5rem;}
 
 
.about_wrapper .about_element.element {
  top: 0%;
  left: 0%;
}
.about_wrapper .about_element.element_2 {
  bottom: 0%;
  left: -7%;
}
.about_wrapper .about_element_3 {
  position: absolute;
  bottom: 0%;
  right: 0%;
  z-index: -1;
}

.single_about_img.single_about_img_2 {
  position: relative;
  z-index: 1;
}

.single_about_img.single_about_img_2::after {
  position: absolute;
  clear: both;
  content: "";
  width: 200px;
  height: 200px;
  background-color: #00baff;
  bottom: -3.5%;
  left: -3%;
  z-index: -1;
}

.about_funfact_items {
  z-index: 1;
  position: relative;
  border-radius: .25rem;
  width: 100%;
  height: auto;
  padding: 25px 10px 15px 10px;
  /* margin: 25px 0; */
  background-size: cover;
}
.about_funfact_items.about_funfact_items_2 {
  z-index: 1;
  position: relative;
  width: 100%;
}
.about_funfact_items.about_funfact_items_2:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(34, 33, 39, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: .5;
}
.about_funfact_items.left_fun {
  margin-left: -80px;
}
@media (max-width: 992px) {
  .about_funfact_items.left_fun {
    margin-left: 0px;
  }
}
.about_funfact_items:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(3, 58, 91, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about_funfact_items::before {
  border-radius: 4px;
}
.about_funfact_items .icons {
  margin-bottom: 10px;
}
.about_funfact_items h5 {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  /*font-size: 20px;*/
  line-height: 100%;
  color: #FFF;
  font-size: 1.25rem;
}
.about_funfact_items h3 {
  font-weight: 400;
  margin-bottom: 0;
  font-style: normal;
  line-height: 100%;
  color: #fff;
  font-size: 2.15rem;
}

.about_content.about_content_2 .section-title h2 {
  /* font-style: normal; */
  /* font-weight: 400; */
  font-size: 2rem;
  /* line-height: 60px; */
  /* color: #222127; */
  /* font-family: "Prata", serif; */
}
.about_content p {
  margin: 20px 0px;
}
.about_content ul > li {
  padding-left: 25px;
  position: relative;
  margin: 10px 0px;
}
.about_content ul > li::after {
  top: 0;
  left: 0;
  z-index: 1;
  clear: both;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #B69D74;
  content: "\f336";
  position: absolute;
}
.about_content .author_name h5 {
  font-family: "Prata", serif;
  color: #222127;
  margin-bottom: 0;
}
.about_content .author_name small {
  color: #666666;
}
.about_content a.btnd {
  text-decoration: none;
  color: #3f3f3f; /*#253858*;*/
  margin: 0px 20px;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #3f3f3f; /*#253858;*/
}

.about_content a.btnd:hover {
  /* color: #B69D74; */
  text-decoration: none;
  border: 0;
  margin-bottom: 21px;
}

.single_about_left {
  position: relative;
}
.single_about_left.single_about_left-2 .our-company__meida {
  border-radius: 4px;
  overflow: hidden;
}
.single_about_left.single_about_left-2 .our-company__meida img {
  -o-object-fit: cover;
     object-fit: cover;
}
.single_about_left.single_about_left-2 .our-company_bg {
  border-radius: 4px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}
.single_about_left.single_about_left-2 .our-company_bg img {
  -o-object-fit: cover;
     object-fit: cover;
}


.grid_container.grid_container-docs,
.grid_container.grid_container-situations,
.grid_container.grid_container-plenum
 {
   margin-left: 1px;
   margin-right: 3px;
   grid-template-columns: repeat(3,1fr);
}

.grid_container.grid_container-situations .grid_container_situations-item,
.grid_container.grid_container-plenum .grid_container_plenum-item
 {
    position: relative;
    display: block;
    height: 100%;
    min-height: 90px;
    margin-bottom: 26px;
    border: 1px solid #f0f0f0;
    overflow: hidden;
    padding: 15px 0px 0 17px;
    border-radius: 0.25rem;
    box-shadow: 1px 0 0 0 #e2e2e2, 0 1px 0 0 #e2e2e2, 1px 1px 0 0 #e2e2e2, inset 1px 0 0 0 #e2e2e2, inset 0 1px 0 0 #e2e2e2;
    background-color: transparent;
    text-decoration: none;
    transition: box-shadow .3s;
}

.grid_container.grid_container-plenum .grid_container_plenum-item {
    min-height: 100px;
}
@media (min-width:1025px) {
.grid_container.grid_container-situations .grid_container_situations-item:hover,
.grid_container.grid_container-plenum .grid_container_plenum-item:hover {
    -webkit-box-shadow: 3px 4px 30px rgba(0, 0, 0, .15);
    box-shadow: 3px 4px 30px rgba(0, 0, 0, .15)
}
}

.grid_container.grid_container-situations .grid_container_situations-item a,
.grid_container.grid_container-plenum .grid_container_plenum-item a 
 {
	text-decoration: none;
	cursor: pointer;
}
.grid_container.grid_container-situations .grid_container_situations-item .card-body__link-open,
.grid_container.grid_container-plenum .grid_container_plenum-item .card-body__link-open,
.document_tpl_card .card-body__link-open {
    text-decoration: none;
    color: #3f3f3f /*#253858*/;
    display: inline-block;
    border-bottom: 1px solid #3f3f3f /*#253858*/;
    position: absolute;
    bottom: 15px;
    left: 16px;
    z-index: 1;
    margin-bottom: 0 !important;
	transition: all 3s ease-in-out;
}
.grid_container.grid_container-situations .grid_container_situations-item a:hover .card-body__link-open,
.grid_container.grid_container-plenum .grid_container_plenum-item a:hover .card-body__link-open,
.document_tpl_card a:hover .card-body__link-open {
    text-decoration: none;
	border-bottom-color: transparent;
	border: 0;
}


.grid_container .document_tpl_card,
.grid_container .grid_container_docs-item {
    position: relative;
    display: block;
    height: 100%;
    min-height: 100px;
    /* color: #000; */
    margin-bottom: 26px;
    border-radius: 0.25rem;
    border: 1px solid #f0f0f0;
    overflow: hidden;
    padding: 15px 5px 0 15px;

  border-radius: .25rem;
  -webkit-box-shadow: 1px 0 0 0 #e2e2e2, 0 1px 0 0 #e2e2e2, 1px 1px 0 0 #e2e2e2, 1px 0 0 0 #e2e2e2 inset, 0 1px 0 0 #e2e2e2 inset;
  box-shadow: 1px 0 0 0 #e2e2e2, 0 1px 0 0 #e2e2e2, 1px 1px 0 0 #e2e2e2, 1px 0 0 0 #e2e2e2 inset, 0 1px 0 0 #e2e2e2 inset;
  background-color: transparent /*#fff*/;
  text-decoration: none;
  -webkit-transition: -webkit-box-shadow .3s;
  transition: -webkit-box-shadow .3s;
  transition: box-shadow .3s;
  transition: box-shadow .3s, -webkit-box-shadow .3s
}


@media (min-width:1025px) {
.grid_container  .document_tpl_card:hover,
.grid_container  .grid_container_docs-item:hover,
.grid_container.grid_container-situations .grid_container_situations-item:hover,
.grid_container.grid_container-plenum .grid_container_plenum-item:hover {
    -webkit-box-shadow: 3px 4px 30px rgba(0, 0, 0, .15);
    box-shadow: 3px 4px 30px rgba(0, 0, 0, .15);
	background-color: #ffffff;
}

.grid_container  .document_tpl_card:hover a,
.grid_container  .grid_container_docs-item:hover a {
    border-bottom-color: transparent;
  }
}
.document_tpl_card a,
.grid_container_docs-item a {
    display: inline-block;
}
	
/*
.document_tpl_card a,
.grid_container_docs-item a {
    text-decoration: none;
    color: #3f3f3f;
    display: inline-block;
    border-bottom: 1px solid #3f3f3f;
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 1;
    margin-bottom: 0 !important;
}*/
.document_tpl_card img,
.grid_container_docs-item img,
.grid_container_situations-item img,
.grid_container_plenum-item img {
    display: block;
    float: right;
    right: -5px;
    top: 5px;
    position: absolute;
    z-index: 0;
    height: 100px;
    /*vertical-align: middle;/**/
}
.document_tpl_card img.card-body__icon,
.grid_container_docs-item img.card-body__icon{
    right: 10px !important;
    top: 10px !important;
	height: 70px;
}

.document_tpl_card img.card-body__icon-info,
.grid_container_docs-item img.card-body__icon-info{
    width: 70px;
    right: 5px !important;
    top: 5px !important;	
}

.grid_container_situations-item img.card-body__icon-info,
.grid_container_plenum-item img.card-body__icon-info
{
    width: 65px;
	height: auto;
    right: 5px !important;
    top: 19px !important;
    opacity: .3;	
}
.grid_container_situations-item a:hover img.card-body__icon-info,
.grid_container_plenum-item a:hover img.card-body__icon-info{
    opacity: .4;
    transition: .3s ease;	
}
.grid_container_situations-item a,
.grid_container_plenum-item a {
	color: #3f3f3f /*#212529*/;
	cursor: pointer;
    display: block;
    height: 100%;	
}



.document_tpl_card  .card-body__box,
.grid_container_docs-item .card-body__box,
.grid_container_situations-item .card-body__box,
.grid_container_plenum-item .card-body__box{
    width: 82%;
    display: block;
}
.document_tpl_card h4,
.grid_container_docs-item h4,
.grid_container_situations-item h4,
.grid_container_plenum-item h4 {
    color: #3f3f3f /*#253858*/;
    margin-bottom: 10px;
    font-size: 1.1rem;
	position: relative;
	display: inline-block;
	font-weight: 600;
}
.document_tpl_card p,
.grid_container_docs-item p,
.grid_container_situations-item p,
.grid_container_plenum-item p
 {
    padding: 0;
    margin-bottom: 25px;
    font-size: 1rem;
}

	

.grid_container_situations-item a p, 
.grid_container_situations-item a h4, 
.grid_container_situations-item a img,

.grid_container_plenum-item a p, 
.grid_container_plenum-item a h4, 
.grid_container_plenum-item a img
{cursor: pointer;}

.grid_container_situations-item a h4,
.grid_container_plenum-item a h4
{
	border-bottom: 1px solid transparent;
	display:inline;
	position: relative;
}
/*
.grid_container_situations-item a:hover h4,
.grid_container_plenum-item a:hover h4
{
	border-bottom: 1px solid #3f3f3f #253858;
	transition: 3s ease;
}*/
.grid_container_plenum-item .card-body__box {
    width: 79%;
}
.grid_container_plenum-item img.card-body__icon-plenum {
    width: 75px;
    height: auto;
    right: 15px!important;
    top: 2px!important;
    opacity: .3;
}




.alert-box_wrapper {color: #253858;}
.alert-box_wrapper .alert{padding: 1.45rem 1.25rem 1.5rem 1.25rem;}
.alert-box_wrapper p {
    color: #253858;
}
.alert-box_wrapper hr {
    border-top: 1px solid #055160;
    width: 50%;
    text-align: center;
    margin: 1.7rem auto 1.4rem;
    opacity: .5;	
}

.alert-box_wrapper .alert-info {color: #253858 !important;}
.alert-box_wrapper .alert-box_title.alert-heading{
	color: #253858 !important;
}

.alert-box_wrapper .alert-box_link {
    color: #253858;
    font-weight: 500;
}
.alert-box_wrapper .alert-box_link:hover {
    color: #253858;
    font-weight: 500;
	border-bottom: 1px solid #253858;
}
.alert-box_wrapper .alert-box_btn-access {
    padding: .2rem 1.5rem .4rem;
	font-size: 1.25rem;
    color: #fff;
    background-color: #00baff;
    border-color: #00baff;
}

.alert-box_wrapper .alert-box_btn-access:hover {
    color: #253858;
    /* border-width: 2px; */
    background-color: transparent;
    /* font-weight: 500; */
    border-color: #253858;
}




.header-phone {
	white-space: pre-line;
}
.header-phone a{
    display: block;
    position: relative;
    float: left;
}
.header-phone small {
    line-height: 1rem;
    font-weight: normal;
	white-space: pre-line;
}










 

div#accordion .accordion {
  border-radius: .25rem;
  -webkit-box-shadow: 1px 0 0 0 #e2e2e2, 0 1px 0 0 #e2e2e2, 1px 1px 0 0 #e2e2e2, 1px 0 0 0 #e2e2e2 inset, 0 1px 0 0 #e2e2e2 inset;
  box-shadow: 1px 0 0 0 #e2e2e2, 0 1px 0 0 #e2e2e2, 1px 1px 0 0 #e2e2e2, 1px 0 0 0 #e2e2e2 inset, 0 1px 0 0 #e2e2e2 inset;
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow .3s ease;
  transition: -webkit-box-shadow .3s ease;
  transition: box-shadow .3s ease;
  transition: box-shadow .3s ease, -webkit-box-shadow .3s ease
}

div#accordion .accordion:not(:last-of-type) {
  margin-bottom: 12px;
}

@media (min-width:1025px) {
  div#accordion .accordion:hover {
    -webkit-box-shadow: 3px 4px 30px rgba(0, 0, 0, .15);
    box-shadow: 3px 4px 30px rgba(0, 0, 0, .15)
  }
}

div#accordion .accordion:hover .accordion__title {
  border-bottom-color: transparent;
}

div#accordion .accordion.opened {
  -webkit-box-shadow: 3px 4px 30px rgba(0, 0, 0, .15);
  box-shadow: 3px 4px 30px rgba(0, 0, 0, .15)
}

div#accordion .accordion.opened .accordion__head {
  padding-bottom: 20px;
}

div#accordion .accordion.opened .accordion__head svg {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

div#accordion .accordion.opened .accordion__body {
  display: block;
  -webkit-animation: spoiler 1s;
  animation: spoiler 1s;
}

div#accordion .accordion.opened .accordion__title {
  border-bottom-color: transparent;
}

.accordion__head {
  position: relative;
  padding: 22px 75px 21px 32px;
  cursor: pointer
}

@media (max-width:520px) {
  .accordion__head {
    padding: 20px
  }
}

.accordion__head svg {
  position: absolute;
  right: 44px;
  top: 42px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: .3s ease;
  transition: .3s ease
}

@media (max-width:520px) {
  .accordion__head svg {
    display: none;
  }
}

.accordion__title {
  display: inline;
  max-width: 886px;
  margin-right: 20px;
  color: #000;
  font-size: 1.35rem;
  font-weight: 400;
  line-height: 30px;
  border-bottom: 1px dotted #000;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

@media (max-width:520px) {
  .accordion__title {
    margin-right: 0;
    font-size: 22px;
    line-height: 24px
  }
}

.accordion__body {
  display: none;
  padding: 0 24px 32px 32px;
}
.accordion__body.show {
  display: block;

}

@media (max-width:520px) {
  .accordion__body {
    padding: 0 20px 20px;
  }
}

.accordion__text {
  /*max-width: 886px;*/
  color: #000
}

.accordion__text p {
  margin: 0;
  letter-spacing: -.36px;
}

.accordion__text p:not(:last-child) {
  margin: 0 0 19px 0;
}

.accordion__item:not(:last-of-type) {
  margin-bottom: 20px;
}

.accordion__item-name {
  margin: 0 0 4px 0;
  color: #afafaf;
}

.accordion__item-value {
  margin: 0;
  color: #000;
  text-decoration: none;
  letter-spacing: -.36px;
}




.grid_container .grid_container_item-head {
    position: relative;
    display: block;
    height: 100%;
    min-height: 200px;
    color: #000;
    margin-bottom: 20px;
    border-radius: 0.25rem;
    border: 0px solid #f0f0f0;
    overflow: hidden;
    padding: 0px 25px 0 0;
    margin-bottom: 1.5rem;
}
.grid_container .grid_container_item-head_title {
    font-weight: 600;
    padding: 0 0 4px 0;
    color: #000;
    font-size: 18px;
    display: inline-block;
    line-height: 1.4;
}
.grid_container .grid_container_item-head_description {
    padding: 4px 0 0 0;
    color: #000;
}



.grid_container_item-head_title h2,
.about_content .section-title h2 {
	position: relative;
	display: inline-block;
	padding-bottom: 10px;
}
.grid_container_item-head_title h2:after,
.about_content .section-title h2:after  {
  bottom: 0%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 3px;
  content: "";
  position: absolute;
  background: #00baff;
  box-sizing: border-box;
  transition: 3s linear;
}

.grid_container_item-head_title h2:hover:after,
.about_content .section-title h2:hover:after {
	background: transparent;
}



/*--------------------------------
    20. FAQ
    --------------------------------*/

.faq-div {
  padding: 26px 0 30px;
  border-bottom: 1px solid #dfdfdf;
}

.faq-div h4 span {
  color: #3f3f3f;
  /*margin-left: 10px;*/
  font-size: 1.05em;
}
.grid_container-faqs .faq-div:first-child {
  padding-top: 0;
}
.grid_container-faqs .faq-div:last-child {
  border-bottom: 0;
}
.faq-div h4 {
  color: #00baff;
  font-size:1.25rem;
  margin-bottom: .5em;
}

.faq-div a{
  text-decoration: none;
  color: #3f3f3f; /*#253858*;*/
  margin-bottom: 0; /*20px;*/
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #3f3f3f; /*#253858;*/
}

.faq-div a:hover {
  /* color: #B69D74; */
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
/*end of FAQ*/


/* right sidebar*/
.right-nav {
    /*width: 25%;*/
    float: left;
    padding-left: 25px;
    margin-left: 10%;
    border-left: 1px solid #ccc;
	width: 100%;
}

.right-nav-page {
    /*width: 25%;*/
    float: left;
    margin-left: 10%;
    border: 0;
}
.right-nav-page .aside-widget {
    /* margin-left: 5%; */
    background-color: rgb(230, 245, 251);
    border: 2px solid rgb(194, 235, 250);
    display: inline-block;
    /* height: 100%; */
    /* padding-left: 5%; */
    width: 100%;
}
.right-nav-page  .aside-widget__image {
    width: 100%;
    height: auto;
    max-width: 220px;
}

.right-nav-page  .aside-widget__image2 {
    width: 100%;
    height: auto;
    max-width: 100%;
    padding: 20px;
    padding-right: 25px;
}




.right-nav-page .aside-widget__title
{text-align: center;}



.right-nav-page a.go_to_callpage_btn {
    position: relative;
    display: grid!important;
    text-align: center;
    box-shadow: inset 0 0 0 3px #006dd4;
    color: #fff;
    background-color: initial;
    box-shadow: none!important;
    background: transparent!important;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    text-decoration: none;
    box-shadow: none;
    padding: 1em 1em;
    transition: all .3s ease;
    position: relative;
    margin: 0 auto;
	width: 100%;
}

.right-nav-page a.go_to_callpage_btn .go_to_callpage_title {
    color: #fff;
    background-color: #00baff;
    box-shadow: inset 0 0 0 3em #00baff;
}
.right-nav-page .go_to_callpage_btn .go_to_callpage_title {
    color: #3f3f3f;
    box-shadow: inset 0 0 0 3px #00baff;
    border-radius: 0;
    padding: .4em;
    transition: all .3s ease;
    /*float: left;/**/
    position: relative;
    display: inline-block;
    font-size: 1.25rem;
    /* min-width: 195px; */
}


.aside-nav {
    position: sticky;
    /*width: 100%;*/
    z-index: 9;
    padding-left: 10px;
    top: 100px ;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: block;
}
/*
.aside-nav a {
    padding: 7px 20px;
    color: #7f7f7f;
    font-weight: 400;
    display: block;
    font-size: 1em;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}*/
.aside-nav nav {padding-left: 5%;}



.aside-nav nav a {
    /*padding: 0px 0 3px 0;
    font-weight: 400;
    font-size: 1em;*/
    text-decoration: none;
    background-color: initial;
    -webkit-text-decoration-skip: objects;
    /*color: #3f3f3f;*/
    margin: 0 0px 15px 20px;
    position: relative;
	border: 0;
    /*border-bottom: 1px solid transparent;*/
    display: block;
}

.aside-nav nav a, .aside-nav nav a span {
    /*padding: 0px 0 3px 0;*/
    font-weight: 400;
    font-size: 1em;
    text-decoration: none;
    background-color: initial;
    -webkit-text-decoration-skip: objects;
    color: #3f3f3f;
}

.aside-nav nav a span{
    padding: 0px 0 3px 0;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    display: inline-block;
}

.aside-nav nav a:hover span{
  /* color: #B69D74; */
  text-decoration: none;
  border-bottom: 1px solid #3f3f3f;
} 











.sidebar-qa {
    display: block;
    border-top: 1px solid #eaeaea;
}

.sidebar-qa__title {
    padding: 16px 0;
    display: block;
    position: relative;
    font-size: 1.05rem;
    /*line-height: 22px;*/
    color: #585858;
    cursor: pointer;
}

.sidebar-qa__title:after,.sidebar-qa__title:before {
    position: absolute;
    display: block;
    content: '';
    width: 12px;
    height: 2px;
    background: #00baff;
    right: 2px;
    top: 27px;
    -webkit-transform: translate3d(0,-50%,0) rotate(0);
    transform: translate3d(0,-50%,0) rotate(0);
    -webkit-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all
}

.sidebar-qa__title:before {
    -webkit-transform: translate3d(0,-50%,0) rotate(90deg);
    transform: translate3d(0,-50%,0) rotate(90deg);
}

.sidebar-qa__content {
    padding: 0;
    overflow: hidden;
   /* height: 0;*/
    /*opacity: 0;*/
    font-size: 1rem;
    /*line-height: 20px;*/
    -webkit-transition: all .5s ease-out,opacity .75s ease-out;
    -o-transition: all .5s ease-out,opacity .75s ease-out;
    transition: all .5s ease-out,opacity .75s ease-out
}

.sidebar-qa:last-child {
    border-bottom: 1px solid #eaeaea;
}
.sidebar-qa:first-child {
    border-top: 0;
}

.sidebar-qa .sidebar-qa__title.collapsed {
   padding-bottom: 0;
}

.sidebar-qa .sidebar-qa__title.collapsed:before {
    -webkit-transform: translate3d(0,-50%,0) rotate(0);
    transform: translate3d(0,-50%,0) rotate(0)
}

.sidebar-qa .sidebar-qa__content.collapse .sidebar-qa__description{
    /*height: auto;*/
    /*padding: 20px 0;*/
    opacity: 1;
}

@media (min-width: 1024px) {
    .sidebar-qa__title {
        padding:24px 0;
        font-size: 1.05rem;
        /*line-height: 24px*/
    }

    .sidebar-qa__title:after,.sidebar-qa__title:before {
        right: 6px;
        top: 36px
    }

    .sidebar-qa__content {
        font-size: 14px;
        line-height: 22px
    }

    .sidebar-qa .sidebar-qa__content.collapse .sidebar-qa__description{
        padding: 24px 0;
    }
}
.page-wisiwig ul {
list-style: none;
margin-left: 20px !important;
margin-bottom: 30px;
margin-top: 10px;
}
.page-wisiwig ul li{padding-top:7px;}
.page-wisiwig ul li::before {
	content: "\25A0";
	display: inline-block;
	margin-left: -20px;
	width: 20px;
	font-size: 12px;
	color: #00baff;
}
.page-wisiwig {margin-bottom: 30px;}

ul#tagcloud-list {
    margin-top:10px;
	text-align:center;
	padding-left: 0;
}
ul#tagcloud-list li {
    display: inline-block;
    padding: 0px 16px 3px 0;
    font-size: 1rem;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 4px;
    color: #3f3f3f;
    transition: all .20s ease-in-out;
}
ul#tagcloud-list li.all-branches {
    font-weight: bolder;
}
ul#tagcloud-list li:hover {
    color: #000;
}
ul#tagcloud-list li::before {
	content: "#";
	display: inline-block;
}
ul#tagcloud-list li.all-branches::before {
	content: "" !important;
	display: inline-block;
}


.js-content-is-hidden {display: none !important;}


.text-restricted {
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgb(255, 250, 250));
  overflow: hidden;
  position: relative;
  mix-blend-mode: hard-light;
}
.text-restricted:after {
 position: absolute;
 top: 0px;
 background: linear-gradient(transparent, gray);
 left: 0px;
 content: "";
 width: 100%;
 height: 100%;
 pointer-events: none;
}
.alert-restricted p, .alert-restricted a, .alert-restricted button {
    font-weight: 600;
    font-size: 1.15rem;
}

.pay_form_tarif_list div.active .grow {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.pay_form_tarif_list div.active .card {
  background: #e6f5fb;
  border: 2px solid #c2ebfa;
}

.yook-form .is-danger{
  border-color: red;
}

iframe {
  pointer-events: none;
}