.b4_left_image {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 97%);
          clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 97%);
}

@media screen and (max-width: 1600px) {
  .b4_left_image {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 98%);
            clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 98%);
  }
}

@media screen and (max-width: 763px) {
  .b1_description {
    margin: 0;
    margin-top: 20px;
  }
}

section {
  position: relative;
}

section.block {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-top: -150px;
  overflow: hidden;
  padding-top: none;
  padding-bottom: none;
}

section.block.main {
  margin-top: 0;
}

.block_bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  /* width: calc(100vw - 8px); */
  -webkit-clip-path: polygon(0 150px, 100% 0, 100% 90%, 0% 100%);
          clip-path: polygon(0 150px, 100% 0, 100% 90%, 0% 100%);
  -webkit-backdrop-filter: opacity(70%);
          backdrop-filter: opacity(70%);
  /* opacity: .7; */
}

.block_bg.main {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
}

.block_bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: .7;
  z-index: -1;
}

.block__content {
  margin-top: 150px;
  margin-bottom: 150px;
}

#question .block__content {
  margin-top: 200px;
  margin-bottom: 0;
}

#achives .block__content {
  margin-top: 250px;
  margin-bottom: 250px;
}

@media screen and (max-width: 524px) {
  .b1_btn_panel .btn {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 730px) {
  .b3_title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 992px) {
  .b4_left_image {
    display: none;
  }
  #question .block__content {
    margin-bottom: 200px;
  }
  .b4_title {
    margin-top: 0;
    text-align: center;
  }
  .form-question {
    margin-top: 50px;
  }
  .op-personnel-item {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .b5_title {
    font-size: 2.5rem;
  }
  section.block {
    margin-top: -110px;
  }
  .block_bg {
    -webkit-clip-path: polygon(0 80px, 100% 0, 100% 95%, 0% 100%);
            clip-path: polygon(0 80px, 100% 0, 100% 95%, 0% 100%);
  }
  .block__content {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
