.order-consulting__content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 25vw;
}

.order-consulting__femida {
    position: absolute;
    right: 0;
    bottom: 0;
    /* height: 300px; */
    /* width: auto; */
}

#order-consulting {
    overflow: visible;
    margin-top: -400px;
    z-index: 2;
}

@media screen and (max-width: 1669px) {
    .order-consulting__femida {
        display: none;
    }

    .order-consulting__callpanel {
        width: 100%;
    }

    .order-consulting__content {
        min-height: 35vw;
    }

    #order-consulting {
        margin-top: -350px;
    }
}

@media screen and (max-width: 1193px) {
    .order-consulting__callpanel {
        display: none;
    }
}