input.input-field__error{color: #f00;border-color: #f00;}
.form-label {
    color: #2c3345;
    display: inline-block;
    font-weight: 500;
    word-break: break-word;
    line-height: 1.2;
}
.form-required {
    color: #f23a3c;
}
.form-line {
    padding: 0;
    margin: 0 0 8px 0;
    border-radius: 3px;
    position: relative;
    width: 100%;
    transition: background-color .15s;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: start;
    align-items: flex-start;
}
.form-line-error {
    background-color: #ffeded;
    color: #FF0000FF;
    border-color: #FF0000FF;
}
.form-hint-line {
	display: none;

}
#form_result .formErrors,
#form_result .formErrors p {color: #f23a3c;/*font-size: 0.95em;*/}

.form-hint-line.form-hint-error {
	color: #f23a3c;
	font-size: .75em;
	display: inline-block;
	width: auto;
	padding-top: 5px;	
}
.form-error-message {
    color: #fff;
    background-color: #f23a3c;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 14.515a7 7 0 110-14 7 7 0 010 14zm-.814-5.86h1.628v-5.21H6.186v5.21zM7 11.259a.82.82 0 00.814-.824A.816.816 0 007 9.631a.813.813 0 100 1.628z' fill='%23fff'/%3E%3C/svg%3E");
    font-size: .75em;
    margin-top: 8px;
    border-radius: 4px;
    background-size: .875em;
    background-position: .375em;
    background-repeat: no-repeat;
    display: inline-block;
    width: auto;
    padding: .25em .5em .25em 1.625em;
}
.form-error-message {
    color: #fff;
    font-size: .75em;
    -ms-flex: 1;
    flex: 1;
}
.form-line-error .form-validation-error {
    border-color: #f23a3c;
}
.btn.btn-biryza.submit-button {
    border: 1px solid #00baff;
    padding: 0px 10px 1px 10px;
    width: max-content;
    text-align: center;
    font-weight: 600;
    height: 38px;
    line-height: 20px;
    box-shadow: none;
    display: table-cell;
    color: #fff /*#04b6f5*/;
    background-color: #00baff;
}
.btn.btn-biryza.submit-button:hover {
    color: #04b6f5;
    background-color: #ffffff;
}
.submit-button {
    width: auto;
    min-width: 180px;
    color: #4bb7ff;
    border-color: #4bb7ff;
    background-color: #fff;
}
.submit-button[disabled] {
    opacity: .2;
}
.jf-form-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 3em;
    width: auto;
    min-width: 128px;
    color: #2c3345;
    font-size: 1em;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    cursor: pointer;
    font-weight: 500;
    font-family: inherit;
}
.form-buttons-wrapper, .form-submit-clear-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
   /* -ms-flex-pack: center;
    justify-content: center;*/
    position: relative;
    padding: 7px 0 24px 0;
    border:0;
}
.service-login {
    font-size: 14px;
    color: inherit;
    margin-bottom: 8px;
}