@media screen and (max-width: 992px) {
  .page-docs.page .page-docs__content {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 75vh max-content;
        grid-template-rows: 75vh -webkit-max-content;
        grid-template-rows: 75vh max-content;
    gap: 2rem;
  }
  .page-docs.page .page-docs__content .page-docs__subjects {
    -ms-grid-row: 2;
    grid-row: 2;
  }
  .page-docs.page .page-docs__content .page-docs__map {
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .page-docs.page .page-docs__content #region_map {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
  }
}

.PREloader {/*max-height: 34.375rem;*/
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.card .card-body .card-title {position: relative;}
.card .card-body .card-title img{
	float: left;
    max-width: 32px;
    vertical-align: middle;
    position: relative;
}
.card .card-body .card-title h4{
	    margin-left: 40px;
}

html {
  --scrollbarBG: #f0f0f0;
  --thumbBG: #00baff;
}
body::-webkit-scrollbar, .PREloader::-webkit-scrollbar {
  width: 8px;
}
body,.PREloader {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track,.PREloader::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb, .PREloader::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: .25rem;
  border: 1px solid var(--scrollbarBG);
}
