.page-docs__subjects .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 1px solid #f0f0f0;
    border-radius: .25rem;
    box-shadow: 1px 0 0 0 #e2e2e2, 0 1px 0 0 #e2e2e2, 1px 1px 0 0 #e2e2e2, inset 1px 0 0 0 #e2e2e2, inset 0 1px 0 0 #e2e2e2;
    padding: 15px 17px;
}
.page-docs__subjects .card:hover{
	background-color: #fff;
	box-shadow: none;
}
.page-docs__subjects .card-body {
    padding: 0;
}

.edgtf-grid-col-3:after,.edgtf-grid-col-3:before {
    content: " ";
    display: table
}
.edgtf-grid-col-3 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    box-sizing: border-box
}
.edgtf-grid-col-3 {
    float: left;
    width: 25%
}

.edgtf-grid-col-3:after {
    clear: both
}

@media only screen and (max-width: 1024px) {
    .edgtf-grid-col-3 {
        width:100%;
        float: none
    }
}
.edgtf-content-bottom .widget:last-child {
    margin: 0!important
}


.edgtf-content-bottom .widget {
    margin: 0;
    text-align: center;
}
.card-body h4 {
    color: #00baff;
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.card-body h4 span {
    color: #3f3f3f;
    /* font-size: 1.05em; */
}
.widget {
    margin: 0 0 7px;
}

.widget .edgtf-widget-title {
    margin: 0;
	font-size: 1.05rem;
}

/*.widget .edgtf-widget-title:after {
    content: '.';
    color: #f45f0b
}*/

.widget .edgtf-widget-title a {
    color: inherit
}


.widget.edgtf-opening-hours-widget {
    margin: 0 0 3px!important;
}

.edgtf-opening-hours-holder {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle
}

.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner {
    display: table;
    width: 100%;
    font-size: .95em;
    line-height: 1em;
    /* font-weight: 500; */
    letter-spacing: .02em;
    text-transform: lowercase;
    color: #3f3f3f;
}

.edgtf-page-footer .edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner {
    border-color: #fff
}

.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner>* {
    display: table-cell;
    white-space: nowrap;
    box-sizing: border-box
}

.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner .edgtf-opening-hours-line {
    width: 98%;
    border-bottom: 2px dotted #e1e1e1;
}

.edgtf-page-footer .edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner .edgtf-opening-hours-line {
    border-color: #fff
}

.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner .edgtf-opening-hours-day-holder,.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner .edgtf-opening-hours-time {
    width: 1%
}

.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner .edgtf-opening-hours-day-holder {
    padding-right: 6px
}

.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner .edgtf-opening-hours-day-holder .edgtf-opening-hours-day {
    display: inline-block
}

.edgtf-opening-hours-holder .edgtf-opening-hours-holder-inner .edgtf-opening-hours-time {
     min-width:40px; 
     padding-left:6px; 
     text-align:right; 
}
