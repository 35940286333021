#application {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-top: 200px;
    padding-bottom: 150px;
    overflow: hidden;
}

#application .app_btn {
    height: 75px;
    width: auto;
}

#application .application__btn-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
}


#application .application__content {
    flex-basis: 50%;
    flex-grow: 1;
}

div.application__footage {
    margin-top: -200px;
    margin-bottom: 200px;
}

.application__footage .bg-footage-1 {
    min-height: 50vh;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
}

@media screen and (max-width: 768px) {
    .application__footage .bg-footage-1 {
        transform: translateX(-350px);
        margin-right: -350px;        
    }
}

@media screen and (max-width: 532px) {
    #application .application__title {
        font-size: 2.2rem;
    }

    #application .app_btn {
        height: 50px;
    }

    #application .application__btn-group {
        gap: 10px;
        margin-top: 40px;
    }
}